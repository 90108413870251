<template><div class="k-editor-wrapper-outer"><div class="k-editor-wrapper k-case-document-editor" :class="top_css" :style="top_style_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="cancel_edit"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex">
		<div><v-icon class="mr-1">fas fa-edit</v-icon> {{is_new_document?'New':'Edit'}} Document {{is_mirror?'Settings':''}}</div>
		<v-spacer/>
		<v-icon @click="U.show_help('edit_document')" class="k-editor-info-icon mr-2">fas fa-info-circle</v-icon>
	</div>
	<div class="k-case-item-editor-scrollable k-case-item-editor-scrollable-taller" style="font-size:14px">
		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">*Identifier:</div>
			<div v-html="CFDocument.identifier"></div>
		</div>

		<div class="k-case-ie-line mb-1" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">*URI Domain:</div>
			<div class="k-case-ie-line-body">
				<v-text-field background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="uri_domain" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>
		<div class="text-right mb-3" v-show="form_active&&!is_mirror" style="font-size:10px">{{updated_uri}}</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">*Title:</div>
			<div class="k-case-ie-line-body">
				<v-text-field background-color="#fff" ref="title_input" outlined dense hide-details :disabled="show_import_interface" v-model="CFDocument.title" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>
		
		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Adoption Status:</div>
			<div class="k-case-ie-line-body">
				<div style="width:190px"><v-select background-color="#fff" v-model="CFDocument.adoptionStatus" :items="adoption_statuses" label="" outlined dense hide-details></v-select></div>
				<div class="k-case-ie-line-label">Language:</div>
				<div style="width:150px"><v-select background-color="#fff" v-model="CFDocument.language" :items="languages" label="" outlined dense hide-details></v-select></div>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Implementation<br>Start Date:</div>
			<div class="k-case-ie-line-body">
				<div style="width:164px"><v-menu ref="statusStartDateMenu" v-model="statusStartDateMenu" :close-on-content-click="false" :return-value.sync="CFDocument.statusStartDate" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-on="on" v-bind="attrs" background-color="#fff" v-model="CFDocument.statusStartDate" label="" outlined dense hide-details clearable></v-text-field>
					</template>
					<v-date-picker v-model="CFDocument.statusStartDate" no-title scrollable @input="$refs.statusStartDateMenu.save(CFDocument.statusStartDate)">
						<v-btn v-show="CFDocument.statusStartDate" small text color="red darken-2" @click="$refs.statusStartDateMenu.save('')"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Clear</v-btn>
						<v-spacer></v-spacer>
						<v-btn small text color="primary" @click="statusStartDateMenu=false"><v-icon small class="mr-1">fas fa-circle-xmark</v-icon>Close</v-btn>
					</v-date-picker>
				</v-menu></div>

				<div class="k-case-ie-line-label" style="margin-left:34px">Retirement<br>Date:</div>
				<div style="width:164px"><v-menu ref="statusEndDateMenu" v-model="statusEndDateMenu" :close-on-content-click="false" :return-value.sync="CFDocument.statusEndDate" transition="scale-transition" offset-y nudge-left="120" min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-on="on" v-bind="attrs" background-color="#fff" v-model="CFDocument.statusEndDate" label="" outlined dense hide-details clearable></v-text-field>
					</template>
					<v-date-picker v-model="CFDocument.statusEndDate" no-title scrollable @input="$refs.statusEndDateMenu.save(CFDocument.statusEndDate)">
						<v-btn v-show="CFDocument.statusEndDate" small text color="red darken-2" @click="$refs.statusEndDateMenu.save('')"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Clear</v-btn>
						<v-spacer></v-spacer>
						<v-btn small text color="primary" @click="statusEndDateMenu=false"><v-icon small class="mr-1">fas fa-circle-xmark</v-icon>Close</v-btn>
					</v-date-picker>
				</v-menu></div>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Official Source URL:</div>
			<div class="k-case-ie-line-body">
				<v-text-field background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="CFDocument.officialSourceURL" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Subject(s):</div>
			<div class="k-case-ie-line-body">
				<v-autocomplete :menu-props="'dense'" small-chips deletable-chips multiple hide-details dense outlined background-color="#fff" :disabled="show_import_interface" :items="subject_options" v-model="subjects" @change="subjects_changed"></v-autocomplete>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Description:</div>
			<div class="k-case-ie-line-body">
				<v-textarea background-color="#fff" outlined dense hide-details v-model="CFDocument.description" placeholder="" rows="1" auto-grow clearable></v-textarea>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">*Creator:<br><span style="font-weight:normal">(issuing agency)</span></div>
			<div class="k-case-ie-line-body">
				<!-- TODO: drop-down with other creators -->
				<v-text-field background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="CFDocument.creator" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Publisher:<br><span style="font-weight:normal">(CASE author)</span></div>
			<div class="k-case-ie-line-body">
				<v-text-field background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="CFDocument.publisher" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>
		
		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Framework Type:</div>
			<div class="k-case-ie-line-body">
				<v-text-field background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="CFDocument.frameworkType" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Version:</div>
			<div class="k-case-ie-line-body">
				<v-text-field background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="CFDocument.version" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label">Notes:</div>
			<div class="k-case-ie-line-body">
				<v-textarea background-color="#fff" outlined dense hide-details v-model="CFDocument.notes" placeholder="" rows="1" auto-grow clearable></v-textarea>
			</div>
		</div>

		<div class="k-case-ie-line" style="align-items:flex-start" v-show="form_active&&!is_mirror">
			<div class="k-case-ie-line-label mt-3">License:</div>
			<div class="k-case-ie-line-body" style="flex-wrap:wrap">
				<v-text-field style="flex:1 0 100%" background-color="#fff" outlined dense hide-details :disabled="show_import_interface" label="License Title" v-model="CFLicense.title" placeholder="" autocomplete="new-password"></v-text-field>
				<v-textarea style="flex:1 0 100%" class="mt-2" background-color="#fff" outlined dense hide-details :disabled="show_import_interface" v-model="CFLicense.licenseText" label="License Text" placeholder="" rows="2" auto-grow clearable></v-textarea>
			</div>
		</div>

		<div v-show="form_active">
			<div style="border-top:1px solid #ccc; font-size:16px" class="mt-2 py-2 text-center"><b>{{app_name}} Settings</b></div>

			<div class="k-case-ie-line align-start">
				<div class="k-case-ie-line-label mt-2">Color:</div>
				<div class="k-case-ie-line-body align-start">
					<div style="width:150px"><v-select background-color="#fff" v-model="selected_color" :items="colors" label="" outlined dense hide-details></v-select></div>
					<ColorPicker v-if="show_color_picker" :color="ss_framework_data.color" @update_color="update_color"></ColorPicker>
					<div class="k-case-ie-line-label mt-2">Image:</div>
					<div style="width:300px" class="text-center">
						<div>
							<v-select v-model="ss_framework_data.image" dense :items="framework_image_select_arr" label="" outlined hide-details></v-select>
							<!-- <v-btn v-if="ss_framework_data.image" small color="secondary" @click="show_image_paster"><v-icon small class="mr-1">fas fa-paste</v-icon>New</v-btn>
							<v-btn v-if="ss_framework_data.image" small color="secondary" class="ml-2" @click="clear_image"><v-icon small class="mr-1">fas fa-times</v-icon>Clear</v-btn> -->
						</div>
						<!-- <v-btn v-if="!ss_framework_data.image" small color="primary" @click="show_image_paster"><v-icon small class="mr-1">fas fa-paste</v-icon>Paste Image</v-btn> -->
						<img class="k-case-ie-framework-image elevation-2 mt-2" :src="framework_image_src" />
					</div>
				</div>
			</div>
			<div class="k-case-ie-line">
				<div class="k-case-ie-line-label">Category:</div>
				<div class="k-case-ie-line-body">
					<div style="width:310px"><v-autocomplete background-color="#fff" v-model="ss_framework_data.category" :items="framework_categories" label="" outlined dense hide-details></v-autocomplete></div>
					<v-btn x-small color="primary" class="ml-2" @click="new_category">New Category</v-btn>
				</div>
			</div>
			<div class="k-case-ie-line">
				<div class="k-case-ie-line-label">URL Shortcut(s):</div>
				<div class="k-case-ie-line-body">
					<v-text-field background-color="#fff" outlined dense hide-details v-model="shortcuts" placeholder="" autocomplete="new-password"></v-text-field>
				</div>
			</div>
			<div v-if="!is_mirror" class="k-case-ie-line">
				<div class="k-case-ie-line-label">Supp. Info Label:</div>
				<div class="k-case-ie-line-body">
					<v-text-field background-color="#fff" outlined dense hide-details v-model="ss_framework_data.exemplar_label" placeholder="" autocomplete="new-password"></v-text-field>
				</div>
			</div>
			<div class="k-case-ie-line" v-show="public_review_available">
				<v-checkbox class="mt-0 pt-0 ml-3" v-model="ss_framework_data.public_review_on" hide-details><template v-slot:label><span style="font-size:14px">Open for Public Review comments<v-icon color="light-blue" small @click.stop="U.show_help('public_review_settings')" class="ml-2">fas fa-info-circle</v-icon></span></template></v-checkbox>
				<v-spacer/>
			</div>
			<div v-if="ss_framework_data.public_review_on" class="k-case-ie-line"><div class="ml-4">
				<div v-if="CFDocument.adoptionStatus.toLowerCase().includes('private')" class="mb-2" style="line-height:18px"><b>Note:</b> while the framework is in a “private” adoption status, visitors will not be able to add public review comments unless they are signed in and authorized to view the framework.</div>
				<div class="mb-1">Public Review Description <span style="font-size:12px">(shown to visitors of the framework while open for public review)</span></div>
				<froala-wrapper :config="public_review_description_froala_config" v-model="ss_framework_data.public_review_description" />
			</div></div>
			<div class="k-case-ie-line">
				<v-checkbox class="mt-0 pt-0 ml-3" v-model="ss_framework_data.render_latex" hide-details><template v-slot:label><span style="font-size:14px">Provide LaTeX editing support</span></template></v-checkbox>
				<v-spacer/>
			</div>
			<div class="k-case-ie-line">
				<v-checkbox class="mt-0 pt-0 ml-3" v-model="ss_framework_data.hide_associations" hide-details><template v-slot:label><span style="font-size:14px">Show associations to framework reviewers and editors <b>only</b></span></template></v-checkbox>
				<v-spacer/>
			</div>
			<div class="k-case-ie-line">
				<v-checkbox class="mt-0 pt-0 ml-3" v-model="ss_framework_data.show_progression_tables" hide-details><template v-slot:label><span style="font-size:14px">Show callout tables for collections with progressions</span></template></v-checkbox>
				<v-spacer/>
			</div>
			<div class="k-case-ie-line">
				<v-checkbox class="mt-0 pt-0 ml-3" v-model="ss_framework_data.ignore_status_dates" hide-details><template v-slot:label><span style="font-size:14px">Ignore statusStartDate and statusEndDate (implementation/retirement dates)</span></template></v-checkbox>
				<v-spacer/>
			</div>
		</div>

		<ItemImportInterface v-if="!is_new_document" v-show="text_import_interface_showing" @cancel_import="text_import_interface_showing=false" :editor_component="this" />
		<ItemCSVImportInterface v-if="!is_new_document" v-show="csv_import_interface_showing" @cancel_import="csv_import_interface_showing=false" :editor_component="this" />
		<ItemPDFImportInterface v-if="!is_new_document&&pdf_import_interface_showing" @cancel_import="pdf_import_interface_showing=false" :editor_component="this" />
		<ItemCopyInterface v-if="!is_new_document" v-show="copy_interface_showing" :framework_record="framework_record" :node_being_edited="cftree" @cancel_copy="copy_interface_showing=false" />
		<AssociationImportInterface v-if="!is_new_document" v-show="association_import_interface_showing" @cancel_import="association_import_interface_showing=false" :editor_component="this" />
		<MetadataImportInterface v-if="!is_new_document&&metadata_import_interface_showing" @cancel_import="metadata_import_interface_showing=false" :editor_component="this" />
	</div>

	<div class="k-case-item-editor-buttons" v-show="form_active">
		<div v-show="viewer&&!is_new_document&&!show_import_interface">
			<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" x-small fab color="secondary" class="mr-1" @click="create_top_level_item"><v-icon small style="transform:rotate(90deg);">fas fa-turn-up</v-icon></v-btn></template>Create a new top-level child item (⌘⇧9)</v-tooltip>
			<v-menu :transition="false" bottom left v-model="edit_actions_menu_showing"><template v-slot:activator="{on}"><v-btn v-if="!is_new_document" v-on="on" small color="secondary"><v-icon small class="mr-2">fas fa-ellipsis-v</v-icon>Edit Actions</v-btn></template>
				<v-list dense>
					<v-menu v-if="!is_mirror" :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
						<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
							<v-list-item-icon><v-icon small>fas fa-plus</v-icon></v-list-item-icon><v-list-item-title>Create/Copy/Import top-level child items</v-list-item-title>
							<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
						</v-list-item></template>

						<v-list dense>
							<v-list-item @click="create_top_level_item"><v-list-item-icon><v-icon small style="transform:rotate(90deg);">fas fa-turn-up</v-icon></v-list-item-icon><v-list-item-title><b>Create</b> a new top-level item (⌘⇧9)</v-list-item-title></v-list-item>
							<v-list-item @click="show_copy_interface"><v-list-item-icon><v-icon small>far fa-copy</v-icon></v-list-item-icon><v-list-item-title><b>Copy</b> items from this or another framework (⌘⇧C)</v-list-item-title></v-list-item>
							<v-list-item @click="show_text_import_interface"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> items from <b>text input</b> (⌘⇧I)</v-list-item-title></v-list-item>
							<v-list-item @click="show_csv_import_interface"><v-list-item-icon><v-icon small>fas fa-file-csv</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> items from <b>CSV file</b></v-list-item-title></v-list-item>
							<v-list-item @click="show_pdf_import_interface"><v-list-item-icon><v-icon small>fas fa-file-pdf</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> items from <b>PDF</b> (BETA)</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>

					<v-divider/>

					<v-list-item v-if="!is_mirror" @click="viewer_fn('toggle_move_mode')"><v-list-item-icon><v-icon small>fas fa-arrows-alt</v-icon></v-list-item-icon><v-list-item-title>Move items (⌘⇧.)</v-list-item-title></v-list-item>

					<v-list-item v-if="!is_mirror" @click="viewer_fn('toggle_batch_edit_mode')"><v-list-item-icon><v-icon small>fas fa-wand-magic-sparkles</v-icon></v-list-item-icon><v-list-item-title>Batch update items</v-list-item-title></v-list-item>

					<v-list-item @click="viewer_fn('toggle_make_associations')"><v-list-item-icon><v-icon small>fas fa-arrows-alt-h</v-icon></v-list-item-icon><v-list-item-title>Make associations</v-list-item-title></v-list-item>

					<v-divider/>
					
					<v-menu :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
						<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
							<v-list-item-icon><v-icon small>fas fa-ellipsis-h</v-icon></v-list-item-icon><v-list-item-title>More options</v-list-item-title>
							<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
						</v-list-item></template>

						<v-list dense>
							<v-list-item v-if="!is_mirror" @click="viewer_fn('refresh_lsdoc')"><v-list-item-icon><v-icon small>fas fa-sync-alt</v-icon></v-list-item-icon><v-list-item-title>Refresh framework data</v-list-item-title></v-list-item>
							<v-list-item @click="viewer_fn('show_framework_json')"><v-list-item-icon><v-icon small>fas fa-code</v-icon></v-list-item-icon><v-list-item-title>Show framework JSON</v-list-item-title></v-list-item>
							<v-list-item v-if="!is_mirror" @click="viewer_fn('re_import_framework')"><v-list-item-icon><v-icon small>fas fa-recycle</v-icon></v-list-item-icon><v-list-item-title>Re-Import framework JSON…</v-list-item-title></v-list-item>
							<v-list-item @click="viewer_fn('delete_framework')"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete framework…</v-list-item-title></v-list-item>

							<v-divider/>

							<v-list-item @click="show_association_import_interface"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch import <b>associations</b> from text input</v-list-item-title></v-list-item>
							<v-list-item v-if="!is_mirror" @click="show_metadata_import_interface"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch import <b>item metadata</b> from text input</v-list-item-title></v-list-item>

							<v-divider/>

							<v-list-item v-if="!is_mirror" @click="viewer_fn('reduce_file_size')"><v-list-item-icon><v-icon small>fas fa-soap</v-icon></v-list-item-icon><v-list-item-title>Clean framework JSON…</v-list-item-title></v-list-item>
							<v-list-item v-if="!is_mirror" @click="viewer_fn('clean_line_breaks')"><v-list-item-icon><v-icon small>fas fa-soap</v-icon></v-list-item-icon><v-list-item-title>Clean line breaks in items…</v-list-item-title></v-list-item>
							<v-list-item v-if="!is_mirror" @click="viewer_fn('split_framework',$event)"><v-list-item-icon><v-icon small>fas fa-divide</v-icon></v-list-item-icon><v-list-item-title>Split into multiple frameworks…</v-list-item-title></v-list-item>
							<v-list-item @click="viewer_fn('toggle_stats_mode')"><v-list-item-icon><v-icon small>fas fa-chart-bar</v-icon></v-list-item-icon><v-list-item-title><v-icon x-small class="mr-2">fas fa-flask</v-icon>Calculate/show framework statistics</v-list-item-title></v-list-item>

							<!-- <v-list-item v-if="viewer.viewer_mode=='text'" @click="viewer_fn('toggle_graphic_mode')"><v-list-item-icon><v-icon small>fas fa-project-diagram</v-icon></v-list-item-icon><v-list-item-title><v-icon x-small class="mr-2">fas fa-flask</v-icon>{{viewer.viewer_mode=='text'?'Show':'Hide'}} “Graphic Mode” (BETA)</v-list-item-title></v-list-item> -->

							<v-list-item v-if="!is_mirror" @click="viewer_fn('process_duplicate_items')"><v-list-item-icon><v-icon small>fas fa-compress-alt</v-icon></v-list-item-icon><v-list-item-title><v-icon x-small class="mr-2">fas fa-flask</v-icon>Process duplicate items…</v-list-item-title></v-list-item>
							<v-list-item v-if="!is_mirror" @click="viewer_fn('run_custom_script')"><v-list-item-icon><v-icon small>fas fa-hat-wizard</v-icon></v-list-item-icon><v-list-item-title><v-icon x-small class="mr-2">fas fa-flask</v-icon>Run custom script…</v-list-item-title></v-list-item>

						</v-list>
					</v-menu>

				</v-list>
			</v-menu>
		</div>

		<v-spacer></v-spacer>
		<!-- <v-btn small color="secondary" @click="cancel_edit">Cancel</v-btn> -->
		<v-btn small v-if="changes_pending||!is_new_document" color="primary" @click="save_changes" class="ml-2">
			<span v-if="changes_pending"><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</span>
			<span v-else>Done Editing</span>
		</v-btn>
	</div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ItemImportInterface from './ItemImportInterface'
import ItemCSVImportInterface from './ItemCSVImportInterface'
import ItemPDFImportInterface from './ItemPDFImportInterface'
import ItemCopyInterface from './ItemCopyInterface'
import AssociationImportInterface from './AssociationImportInterface'
import MetadataImportInterface from './MetadataImportInterface'
import ColorPicker from '../utilities/ColorPicker.vue'

export default {
	components: { ItemImportInterface, ItemCSVImportInterface, ItemPDFImportInterface, ItemCopyInterface, AssociationImportInterface, MetadataImportInterface, ColorPicker },
	props: {
		framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return ''} },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		editor_type: 'document',	// used by ItemImportInterface / CopyInterface
		dialog_open: true,
		CFDocument: {},
		CFLicense: {},
		subjects: [],
		ss_framework_data: {},
		last_image: '',
		edit_actions_menu_showing: false,
		text_import_interface_showing: false,
		csv_import_interface_showing: false,
		pdf_import_interface_showing: false,
		copy_interface_showing: false,
		association_import_interface_showing: false,
		metadata_import_interface_showing: false,
		original_document_data: '',
		original_color: 0,
		show_import_interface: false,
		colors: [
			{ value:'0', text: 'Default color (based on subject or identifier)' },
			{ value:'14', text: 'Grey' },
			{ value:'7', text: 'Brown' },
			{ value:'4', text: 'Blue' },
			{ value:'9', text: 'Light Blue' },
			{ value:'5', text: 'Cyan' },
			{ value:'10', text: 'Teal' },
			{ value:'11', text: 'Green' },
			{ value:'6', text: 'Lime' },
			{ value:'13', text: 'Orange' },	// deep orange
			{ value:'12', text: 'Red' },
			{ value:'1', text: 'Pink' },
			{ value:'8', text: 'Purple' },
			{ value:'2', text: 'Deep Purple' },
			{ value:'3', text: 'Indigo' },
			{ value:'custom color', text: '-- CUSTOM COLOR --' },
		],
		app_name: this.$store.state.site_config.app_name,
		statusStartDateMenu: false,
		statusEndDateMenu: false,
		show_color_picker: false,
		selected_color: ''
	}},
	computed: {
		...mapState(['framework_records', 'languages']),
		...mapGetters([]),
		cfo() { return this.framework_record.cfo },
		cftree() { return this.cfo ? this.cfo.cftree : {} },
		adoption_statuses() { 
			let adoption_statuses = object_copy(CFDocument.adoption_statuses)
			let original_status = this.framework_record.json.CFDocument.adoptionStatus
			if (!empty(original_status) && empty(adoption_statuses[original_status])) {
				adoption_statuses.push({value: original_status, text: original_status})
			}
			return adoption_statuses
		},
		is_new_document() {
			// if we're editing the document and its json hasn't been loaded, it must be a new framework
			return !this.framework_record.framework_json_loaded
			// return empty(this.framework_record.lsdoc_identifier)
		},
		framework_maximized() {
			if (empty(this.viewer)) return false
			return this.viewer.maximized
		},
		changes_pending() {
			let new_data = JSON.stringify(this.CFDocument.to_json()) + JSON.stringify(this.CFLicense.to_json()) + JSON.stringify(this.ss_framework_data) + JSON.stringify(this.subjects)
			return new_data != this.original_document_data
		},
		form_active() {
			if (this.text_import_interface_showing || this.csv_import_interface_showing || this.pdf_import_interface_showing || this.copy_interface_showing || this.association_import_interface_showing || this.metadata_import_interface_showing) return false
			return true
		},
		top_css() {
			const framework_color = U.framework_color(this.CFDocument.identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-editor'
			return ''
		},
		top_style_css() {
			return U.framework_color_object(this.CFDocument.identifier, 'editor')
		},
		framework_image_src() {
			// send a fake framework_record into framework_image_src with the data as it's being edited
			let cfdoc = $.extend(true, {}, this.CFDocument)
			cfdoc.subject = this.subjects
			return U.framework_image_src({json:{CFDocument:cfdoc}, ss_framework_data: this.ss_framework_data})
		},
		framework_image_select_arr() {
			let arr = [{value: '0', text:'Default Image (based on subject)'}]
			for (let i = 1; i < U.framework_icons.length; ++i) {
				let item = U.framework_icons[i]
				arr.push({value: item.id+'', text: item.name})
			}
			let i = this.ss_framework_data.image
			if (isNaN(i*1)) {
				arr.push({value: i, text: 'CUSTOM IMAGE'})
				arr.push({value: -1, text: '-- CHANGE CUSTOM IMAGE --'})
			} else {
				arr.push({value: -1, text: '-- PASTE CUSTOM IMAGE --'})
			}
			return arr
		},
		shortcuts: {
			get() { return this.ss_framework_data.shortcuts.join(', ') },
			set(val) {
				this.ss_framework_data.shortcuts = $.trim(val).split(/\s*,\s*/)
			}
		},
		is_mirror() { return this.framework_record.ss_framework_data.is_mirror === 'yes' },
		framework_categories() {
			let arr = []
			for (let c of this.$store.state.framework_categories) {
				arr.push({value:c, text:c.replace(/^\d+\s*/, '')})
			}
			return arr
		},

		// parse uri_domain from CFDocument.uri
		uri_domain: {
			get() { return this.CFDocument.uri.replace(/^(http(s?):\/\/.*?)\/.*/, '$1') },
			set(val) {
				// strip trailing '/' if there
				val = val.replace(/\/$/, '')

				this.CFDocument.uri = U.case_api_url(this.CFDocument.identifier, 'CFDocuments', null, val)
			}
		},
		// value of uri_domain before any user changes were made
		original_uri_domain() { return this.framework_record.json.CFDocument.uri.replace(/^(http(s?):\/\/.*?)\/.*/, '$1') },
		uri_changed() { return this.uri_domain != this.original_uri_domain },
		updated_uri() {
			// if user has changed the uri_domain value, return what the updated document uri will look like
			if (this.uri_changed) {
				return this.CFDocument.uri
				// return U.case_api_url(this.CFDocument.identifier, null, null, this.uri_domain)
			} else {
				// else just return the original uri value
				return this.framework_record.json.CFDocument.uri
			}
		},
		subject_options() {
			// create a list of subject options from all frameworks in the system
			let arr = []
			for (let fr of this.framework_records) {
				let sarr = U.entity_subject_string_array(fr.json.CFDocument)
				if (sarr.length > 0) {
					for (let s of sarr) {
						if (s == '') continue
						if (!arr.includes(s)) arr.push(s)
					}
				}
			}

			// add any items in subjects (i.e. new items)
			for (let s of this.subjects) {
				if (!arr.includes(s)) arr.push(s)
			}

			arr.sort()
			arr.push('NEW SUBJECT')
			return arr
		},
		public_review_available() {
			// the public review option is currently available everywhere; we could restrict it
			return true
			// return (this.$store.state.site_config.agency_url.includes('satchelcommons') || document.location.hostname == 'localhost')
		},
		public_review_description_froala_config() {
			return U.get_froala_config({
				placeholderText: 'e.g. “This framework is open for Public Review until May 25, 20XX”',
				enter: FroalaEditor.ENTER_BR,
			})
		},
	},
	watch: {
		selected_color(new_val, old_val) {
			if (this.selected_color == 'custom color') {
				this.show_color_picker = true
			} else {
				if (isNaN(old_val) && !isNaN(new_val)) {
					this.show_color_picker = false
				}
				this.selected_color = new_val
				this.ss_framework_data.color = new_val 
				this.$store.commit('set', [this.framework_record.ss_framework_data, 'color', this.selected_color])
			}
		},
		"ss_framework_data.color"() {
			this.$store.commit('set', [this.framework_record.ss_framework_data, 'color', this.ss_framework_data.color])
		},
		"ss_framework_data.image"() {
			if (this.ss_framework_data.image == -1) {
				this.show_image_paster()
			} else {
				// set last_image if the user didn't choose to paste a custom image
				this.last_image = this.ss_framework_data.image
			}
		},
	},
	created() {
		vapp.document_editor_component = this

		// save a snapshot of the properties in the original CFDocument to use in the editor form, then capture a stringified version for use in changes_pending
		this.CFDocument = new CFDocument(this.framework_record.json.CFDocument)

		// in Satchel we allow for a single license
		let licenses = oprop(this.framework_record.json, 'CFDefinitions', 'CFLicenses')
		if (licenses && licenses.length > 0) this.CFLicense = new CFLicense(licenses[0])
		else this.CFLicense = new CFLicense()

		this.subjects = U.entity_subject_string_array(this.framework_record.json.CFDocument)

		this.ss_framework_data = new SSFrameworkData(this.framework_record.ss_framework_data)
		this.original_document_data = JSON.stringify(this.CFDocument.to_json()) + JSON.stringify(this.CFLicense.to_json()) + JSON.stringify(this.ss_framework_data) + JSON.stringify(this.subjects)

		// save current color so we can update it on the fly to give the user a preview of what it will look like to change it
		this.original_color = this.framework_record.ss_framework_data.color

		// save current image in last_image
		this.last_image = this.ss_framework_data.image

		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		if (this.viewer) this.viewer.current_editor = this
	},
	mounted() {
		setTimeout(x=>{
			if (empty(this.$refs.title_input) || empty(this.$refs.title_input.$el)) return
			$(this.$refs.title_input.$el).find('input').focus()
			this.editor_card_clicked()
		}, 50)
		this.selected_color = this.ss_framework_data.color
		if (isNaN(this.selected_color)) {
			this.show_color_picker = true
			this.selected_color = 'custom color'
		}
	},
	methods: {
		update_color(color) {
			this.ss_framework_data.color = color
		},
		editor_card_clicked() {
			// when item editor is clicked, make sure it's in front of the framework viewer
			$('.k-case-tree-outer-wrapper').css('z-index', 100)
			$('.k-case-item-editor-outer').css('z-index', 101)
		},
		cancel_edit() {
			// if this is a new item, delete it from state.framework_records
			if (this.is_new_document) {
				let index = this.framework_records.findIndex(x=>x==this.framework_record)
				if (index > -1) {
					console.log('here')
					this.$store.commit('set', [this.framework_records, 'SPLICE', index])
				}
			} else {
				// else for an existing document, make sure color is set to original_color
				this.$store.commit('set', [this.framework_record.ss_framework_data, 'color', this.original_color])
			}

			this.$emit('dialog_cancel')
		},

		create_top_level_item() {
			// start with the document's language
			let child_cfitem_data = {
				language: this.CFDocument.language,
				fullStatement: '',
			}

			// use the CFItem constructor, with complete_data and to_json, to get a new identifier, uri, etc.
			child_cfitem_data = new CFItem(child_cfitem_data)
			child_cfitem_data.complete_data(this.CFDocument)
			child_cfitem_data = child_cfitem_data.to_json()

			// get the sequenceNumber to use for the new item, and put this in the node.sequence parameter to use if/when the new item is saved
			let sequenceNumber = U.get_next_sequenceNumber(this.framework_record.cfo.cftree, this.framework_record)

			// add a new item to the cfo (we'll take it back out if needed); put the sequenceNumber we will save the item to in node.sequence, for use in ItemEditor.save_changes
			// note: since sequenceNumber is guaranteed to be higher than the last index of the parent's children array, the item will always get spliced to the end of children
			let child_node = U.add_child_to_cfo(child_cfitem_data, this.framework_record.cfo.cftree, this.$store, this.framework_record.cfo, sequenceNumber)

			// add the cftree as the new_item_parent_node
			this.$store.commit('set', [child_node, 'new_item_parent_node', this.framework_record.cfo.cftree])

			this.cancel_edit()

			// open the child for editing
			this.viewer.edit_new_child(child_node)
		},

		subjects_changed() {
			if (this.subjects[this.subjects.length-1] == 'NEW SUBJECT') {
				this.subjects.pop()
				this.$prompt({
					text: 'Enter the subject value you’d like to use:',
					acceptText: 'Save',
				}).then(subject => {
					subject = $.trim(subject)
					if (empty(subject)) return
					this.subjects.push(subject)
				}).catch(n=>{console.log(n)}).finally(f=>{})
			}
		},

		new_category() {
			this.$prompt({
				text: 'Enter new framework category:',
				initialValue: '',
				acceptText: 'Done',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				// make sure this doesn't match an existing category, and get the highest category number
				let highest_number = -1
				for (let fc of this.framework_categories) {
					let number = fc.value.replace(/^(\d+)\s*.*/, '$1') * 1
					if (!isNaN(number) && number > highest_number) highest_number = number

					if (fc.text == title) {
						this.$alert('That category title already exists.')
						return
					}
				}

				// add to framework_categories in the store, then set this framework's title
				let new_string = (highest_number + 1) + ' ' + title
				this.$store.commit('set', [this.$store.state.framework_categories, 'PUSH', new_string])
				this.ss_framework_data.category = new_string
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		save_changes() {
			if (!this.changes_pending) {
				this.cancel_edit()
				return
			}

			// for mirrors, user can only change ss_framework_data settings (only these settings will be showing)
			if (this.is_mirror) {
				this.save_mirror_settings()
				return
			}

			if (empty(this.CFDocument.title)) { this.$alert('All CASE documents must specify a title.'); return; }
			if (empty(this.CFDocument.creator)) { this.$alert('All CASE documents must specify a creator.'); return; }

			// if the user updated the uri domain, do a basic sanity check on the value; we'll update items below
			if (this.uri_changed && this.uri_domain.search(/^http(s?):\/\/.*\./) == -1) {
				this.$alert('The URI domain should start with “http:” or “https:”.'); return;
			}

			// deal with subjects: if the lengths differ, or if any of the items differ, there's a mismatch, so save subjects
			let original_subjects = U.entity_subject_string_array(this.framework_record.json.CFDocument)
			let subjects_match = original_subjects.length == this.subjects.length
			if (subjects_match) {
				for (let os of original_subjects) {
					if (!this.subjects.includes(os)) {
						subjects_match = false
						break
					}
				}
			}
			if (!subjects_match) {
				// for now at least, Satchel will always save subjects as arrays of strings, so if we had a subjectURI value previously, clear it
				// (note, however, that if an imported framework has a subjectURI, it won't be changed if the subject isn't changed)
				this.CFDocument.subjectURI = []
				this.CFDocument.subject = JSON.parse(JSON.stringify(this.subjects))
			}

			// check shortcuts, trimming and removing blank values
			for (let i = this.ss_framework_data.shortcuts.length-1; i >= 0; --i) {
				let shortcut = $.trim(this.ss_framework_data.shortcuts[i])
				if (shortcut) this.ss_framework_data.shortcuts[i] = shortcut
				else this.ss_framework_data.shortcuts.splice(i, 1)
			}

			// complete the document, filling in lastChangeDateTime
			this.CFDocument.complete_data(this.CFDocument)

			let data = {
				lsdoc_identifier: this.CFDocument.identifier,
				ss_framework_data: this.ss_framework_data.to_json_for_update(),
			}

			let was_new_document = this.is_new_document

			// deal with license. First make sure that if we have a title we also have a text, and vice-versa
			this.CFLicense.title = $.trim(this.CFLicense.title)
			this.CFLicense.licenseText = $.trim(this.CFLicense.licenseText)
			if ((this.CFLicense.title && !this.CFLicense.licenseText) || (!this.CFLicense.title && this.CFLicense.licenseText)) {
				this.$alert('To specify a License, you must enter both the License Title and the License Text.')
				return
			}
			let licenses = oprop(this.framework_record.json, 'CFDefinitions', 'CFLicenses')
			// if we already had a license...
			if (licenses && licenses.length > 0) {
				// if we already had a license and it's been cleared, eliminate the CFLicense
				if (!this.CFLicense.title) {
					data.CFDefinitions = { CFLicenses: '*CLEAR*' }

					// also remove the licenseURI field from the CFDocument
					this.CFDocument.licenseURI = new LinkURI()

				// else if the license changed *or the document uri changed*, send it through; no need to update the CFDocument in this case
				} else if (this.CFLicense.title != licenses[0].title || this.CFLicense.licenseText != licenses[0].licenseText || this.uri_changed) {
					// call generate_uri for the license, in case the document's uri value has been updated
					this.CFLicense.generate_uri(this.CFDocument)
					data.CFDefinitions = { CFLicenses: [extobj(this.framework_record.json.CFDefinitions.CFLicenses[0])] }
					data.CFDefinitions.CFLicenses[0].title = this.CFLicense.title
					data.CFDefinitions.CFLicenses[0].licenseText = this.CFLicense.licenseText
					data.CFDefinitions.CFLicenses[0].uri = this.CFLicense.uri
				}

			} else if (this.CFLicense.title) {
				// else we didn't already have a license, so if the user has specified one now, generate a license with a new identifier and uri
				this.CFLicense.generate_identifier()
				this.CFLicense.generate_uri(this.CFDocument)
				this.CFLicense.generate_date()

				data.CFDefinitions = { CFLicenses: [this.CFLicense.to_json()] }

				// add licenseURI to the CFDocument
				this.CFDocument.licenseURI = new LinkURI({
					title: data.CFDefinitions.CFLicenses[0].title,
					identifier: data.CFDefinitions.CFLicenses[0].identifier,
					uri: data.CFDefinitions.CFLicenses[0].uri,
				})
			}

			// add data.CFDocument now, after processing the license
			data.CFDocument = this.CFDocument.to_json_for_update()

			data.show_spinner = false	// don't show spinner -- unless the uri is changed, in which case we'll have to update items and associations too

			// if the user updated the uri domain, the CFDocument will have been already updated by the computed property (and the change will have been applied to the license above)
			if (this.uri_changed) {
				data.show_spinner = true
				data.update_item_types = true

				// apply changes to all CFItems, and relevant associations, here
				data.CFItems = []
				data.CFAssociations = []

				// while we go through the CFItems, keep track of which items we're changing, to make it easier to update CFAssociations below
				let updated_item_identifiers = new Set()
				updated_item_identifiers.add(this.CFDocument.identifier)
				for (let cfi of this.framework_record.json.CFItems) {
					// don't update uri values for items in derivative frameworks that came from the original framework (but do update uri values for new items in the derivative)
					if (this.viewer.derivative_original_json?.CFItems.find(x=>x.identifier == cfi.identifier)) {
						continue
					}

					// for each item (and association), we only have to send to save_framework_data the identifier and the fields to be updated. we always send the uri...
					let o = { identifier: cfi.identifier, uri: U.generate_child_uri(this.CFDocument, cfi.identifier, 'CFItems') }
					// then if the item has a type, we need to send both the CFItemType and the CFItemTypeURI (which will be updated), so that the keep_item_types_in_sync fn will work properly
					if (cfi.CFItemTypeURI) {
						// CFItemTypeURI: start with a copy of the existing object, then update the uri, then add to o 
						o.CFItemTypeURI = new LinkURI(cfi.CFItemTypeURI)
						o.CFItemTypeURI.uri = U.generate_child_uri(this.CFDocument, cfi.CFItemTypeURI.identifier, 'CFItemTypes')
					}
					if (cfi.CFItemType) o.CFItemType = cfi.CFItemType
					data.CFItems.push(o)
					// Add identifiers to set for use when updating CFAssociations below 
					updated_item_identifiers.add(cfi.identifier)
				}

				for (let cfa of this.framework_record.json.CFAssociations) {
					// update the uri for all associations
					let a = { identifier: cfa.identifier, uri: U.generate_child_uri(this.CFDocument, cfa.identifier, 'CFAssociations') }

					// and update the origin/destination iff the origin/destination is from the current framework
					if (updated_item_identifiers.has(cfa.originNodeURI.identifier)) {
						// we have to send in the full "LinkURI" object for originNodeURI and destinationNodeURI
						a.originNodeURI = Object.assign({}, cfa.originNodeURI)
						// note that the uri here may be a CFDocuments api url or a CFItems api url
						a.originNodeURI.uri = U.generate_child_uri(this.CFDocument, cfa.originNodeURI.identifier, (cfa.originNodeURI.identifier == this.CFDocument.identifier) ? 'CFDocuments' : 'CFItems')
					}
					if (updated_item_identifiers.has(cfa.destinationNodeURI.identifier)) {
						a.destinationNodeURI = Object.assign({}, cfa.destinationNodeURI)
						a.destinationNodeURI.uri = U.generate_child_uri(this.CFDocument, cfa.destinationNodeURI.identifier, (cfa.destinationNodeURI.identifier == this.CFDocument.identifier) ? 'CFDocuments' : 'CFItems')
					}

					data.CFAssociations.push(a)
				}

				// have to update CFItemTypes for new uris as well
				if (this.framework_record.json.CFDefinitions?.CFItemTypes) {
					let CFItemTypes = []
					for (let cfit of this.framework_record.json.CFDefinitions.CFItemTypes) {
						let o = extobj(cfit)
						o.uri = U.generate_child_uri(this.CFDocument, cfit.identifier, 'CFItemTypes')
						CFItemTypes.push(o)
					}

					if (empty(data.CFDefinitions)) data.CFDefinitions = {}
					data.CFDefinitions.CFItemTypes = CFItemTypes
					console.log(data)
				}
			}
			
			// if (this.subject_changed) {
			// 	data.show_spinner = true
			// 	// Create a subject array in the data.CFDocument
			// 	data.CFDocument.subject = []
			// 	if (this.subject.includes(",")) {
			// 		this.subject = this.subject.split(",")
			// 		for (let subject of this.subject) {
			// 			data.CFDocument.subject.push(subject)
			// 		}
			// 	}
			// 	else {
			// 		data.CFDocument.subject.push(this.subject)
			// 	}
			// 	console.log(data.CFDocument.subject)
			// }

			this.$store.dispatch('save_framework_data', data).then((result)=>{
				// update this.CFDocument's lastChangeDateTime from result
				this.CFDocument.lastChangeDateTime = result.document_lastChangeDateTime

				// update the framework_record
				this.$store.commit('set', [this.framework_record, 'lsdoc_identifier', this.CFDocument.identifier])
				this.$store.commit('set', [this.framework_record.json, 'CFDocument', this.CFDocument.to_json()])
				this.$store.commit('set', [this.framework_record, 'ss_framework_data', $.extend(true, {}, this.ss_framework_data)])
				if (data.CFDefinitions) {
					if (data.CFDefinitions.CFLicenses == '*CLEAR*') {
						this.$store.commit('set', [this.framework_record.json.CFDefinitions, 'CFLicenses', []])

					} else if (data.CFDefinitions.CFLicenses) {
						if (data.CFDefinitions.CFLicenses.length > 0) {
							// if we updated the license (in CFDefinition), update lastChangeDateTime and save to json
							data.CFDefinitions.CFLicenses[0].lastChangeDateTime = result.document_lastChangeDateTime
						}
						this.$store.commit('set', [this.framework_record.json.CFDefinitions, 'CFLicenses', data.CFDefinitions.CFLicenses])
					}
					// note: save_framework_data dispatch takes care of updating CFItemTypes
				}

				// if this was a new framework, it's no longer new, so set framework_json_loaded to true (there is no json other than the document, which we now do have loaded, so this is appropriate)
				if (!this.is_new_document) {
					this.$store.commit('set', [this.framework_record, 'framework_json_loaded', true])
				}

				// update original_color
				this.original_color = this.framework_record.ss_framework_data.color

				// transfer updates to the document's cftree, if we have one
				if (!empty(this.framework_record.cfo) && !empty(this.framework_record.cfo.cftree)) {
					this.update_cfitem_from_json(this.framework_record.cfo.cftree.cfitem, this.CFDocument.to_json())
				}

				// update cfitems and cfassociations
				if (data.CFItems) for (let cfi of data.CFItems) {
					let j = this.framework_record.json.CFItems.find(x=>x.identifier == cfi.identifier)	// this is guaranteed to be found
					this.$store.commit('set', [j, 'uri', cfi.uri])
					this.$store.commit('set', [this.framework_record.cfo.cfitems[cfi.identifier], 'uri', cfi.uri])
					if (cfi.CFItemTypeURI) {
						this.$store.commit('set', [j, 'CFItemTypeURI', cfi.CFItemTypeURI])
						this.$store.commit('set', [this.framework_record.cfo.cfitems[cfi.identifier], 'CFItemTypeURI', cfi.CFItemTypeURI])
					}
				}
				if (data.CFAssociations) for (let cfa of data.CFAssociations) {
					// note that we don't need to update associations_hash values, because they don't include uris
					let j = this.framework_record.json.CFAssociations.find(x=>x.identifier == cfa.identifier)	// this is guaranteed to be found
					this.$store.commit('set', [j, 'uri', cfa.uri])
					if (cfa.originNodeURI) this.$store.commit('set', [j.originNodeURI, 'uri', cfa.originNodeURI.uri])
					if (cfa.destinationNodeURI) this.$store.commit('set', [j.destinationNodeURI, 'uri', cfa.destinationNodeURI.uri])
				}

				this.original_document_data = JSON.stringify(this.CFDocument.to_json()) + JSON.stringify(this.CFLicense.to_json()) + JSON.stringify(this.ss_framework_data) + JSON.stringify(this.subjects)

				// if this was a new document, open the document and close the dialog
				if (was_new_document) {
					this.$emit('view_framework', this.CFDocument.identifier)
					this.$emit('dialog_cancel')
				}
			})
		},

		// ss_framework_data settings are the only thing that can be edited in a mirror framework_record
		save_mirror_settings() {
			// check shortcuts, trimming and removing blank values
			for (let i = this.ss_framework_data.shortcuts.length-1; i >= 0; --i) {
				let shortcut = $.trim(this.ss_framework_data.shortcuts[i])
				if (shortcut) this.ss_framework_data.shortcuts[i] = shortcut
				else this.ss_framework_data.shortcuts.splice(i, 1)
			}

			let payload = {
				framework_identifier: this.CFDocument.identifier,
				ss_framework_data: this.ss_framework_data.to_json_for_update(),
			}

			U.ajax('save_mirror_settings', payload, result=>{
				if (result.status != 'ok') {
					this.$alert(result.status)
					return
				}
				this.$store.commit('set', [this.framework_record, 'ss_framework_data', $.extend(true, {}, this.ss_framework_data)])
				this.original_color = this.framework_record.ss_framework_data.color
				this.$emit('dialog_cancel')
			})
		},

		// same fn is in ItemEditor and DocumentEditor
		update_cfitem_from_json(cfitem, cfitem_json) {
			// add/update properties in cfitem_json that weren't in cfitem
			for (let key in cfitem_json) {
				let val = cfitem_json[key]
				if (Array.isArray(val)) val = val.concat([])
				else if (typeof(val) == 'object') val = $.extend(true, {}, val)
				this.$store.commit('set', [cfitem, key, val])
			}

			// remove properties from cfitem that *aren't* in cfitem_json
			for (let key in cfitem) {
				// WATCH IT!!! skip properties added by U.create_cfo_cfitem
				if (key == 'tree_nodes' || key == 'stats' || key == 'extensions') continue
				if (typeof(cfitem_json[key]) == 'undefined') {
					this.$store.commit('set', [cfitem, key, '*DELETE_FROM_STORE*'])
				}
			}
		},

		show_copy_interface() {
			this.edit_actions_menu_showing = false
			this.copy_interface_showing = true
		},

		show_text_import_interface() {
			this.edit_actions_menu_showing = false
			this.text_import_interface_showing = true
		},

		show_csv_import_interface() {
			this.edit_actions_menu_showing = false
			this.csv_import_interface_showing = true
		},

		show_pdf_import_interface() {
			this.edit_actions_menu_showing = false
			this.pdf_import_interface_showing = true
		},

		show_association_import_interface() {
			this.edit_actions_menu_showing = false
			this.association_import_interface_showing = true
		},

		show_metadata_import_interface() {
			this.edit_actions_menu_showing = false
			this.metadata_import_interface_showing = true
		},

		show_image_paster() {
			let cancel_paster = x => {
				$(document).off('paste.show_image_paster')
				this.$cancelDialogs()
			}

			$(document).on('paste.show_image_paster', e => {
				// Note tha console.logging the event doesn't show the data in e.originalEvent.clipboardData.items; you need to use the chrome debugger to see the data
				e.preventDefault()

				let items = oprop(e, 'originalEvent', 'clipboardData', 'items')
				let found_image = false
				let IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;
				if (!empty(items) && items.length > 0) {
					if (IMAGE_MIME_REGEX.test(items[0].type)) {
						let data_url = U.create_image_data_url(items[0].getAsFile(), {
							image_format: 'webp',
							max_width: 224,
							max_height: 224,
							callback_fn: o=>{
								// console.log('got the image!', o.img_url.substr(0,100))
								// console.log(o.img_url)
								this.ss_framework_data.image = o.img_url
								found_image = true
								cancel_paster()
							}
						})
					} else if (items[0].type.match('^text/plain')) {
						items[0].getAsString(s=>{
							console.log('found string: ' + s)
							if (s.indexOf('data:image') == 0) {
								// copy of an image pasted in from a different satchel framework
								this.ss_framework_data.image = s
								found_image = true
								cancel_paster()
							}
						})
					}
				}

				if (!found_image) {
					this.$alert('You didn’t paste an image! Try again, or cancel.')
				}
			})

			this.$alert({
			    title: 'Framework Image',
			    text: 'Copy to your clipboard the image you would like to use to represent the framework, then paste (cmd-V [Mac] or ctrl-V [Windows]) the image here.',
			    acceptText: 'Cancel',
				// dialogMaxWidth: 800
			}).then(y => {
				// revert back to last-selected image
				this.ss_framework_data.image = this.last_image
				cancel_paster()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		viewer_fn(fn_name, evt) {
			this.edit_actions_menu_showing = false
			this.viewer[fn_name](evt)
		},
	}
}
</script>

<style lang="scss">
.k-case-document-editor {
	.k-case-ie-line-label {
		width:120px;
		text-align:right;
		margin-right:8px;
		margin-bottom:0;
	}
}

.k-case-ie-framework-image {
	max-width:200px;
	border:1px solid #ccc;
	border-radius:8px;
}

.v-select-menu {
  z-index: 1000 !important;  /* Make sure the dropdown appears above other elements */
}
</style>

<template>
	<div v-if="associations.length>0" class="k-case-tree-item-associations">
		<div v-if="!full_text_showing" class="d-flex k-case-tree-item-associations-condensed">
			<div><v-btn x-small text color="#555" class="k-case-tree-item-associations-show-btn mt-1 k-tight-btn" style="padding-left:2px;padding-right:4px;" @click="show_full_text_clicked=true">Expand <v-icon small class="ml-1">fas fa-caret-down</v-icon></v-btn></div>
			<div v-for="(ao) in all_associations" :key="ao.assoc.identifier" class="k-case-tree-item-association-condensed" :style="framework_color_style(ao.framework_identifier)" :class="association_css_class(ao.assoc, ao.framework_identifier)" @click.stop="association_clicked(ao.assoc)" :data-association-marker="association_line_marker(ao.assoc)">
				<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" class="k-case-tree-item-association-type-icon" small>{{association_type_icon(ao.assoc)}}</v-icon></template>{{association_type(ao.assoc)}}</v-tooltip>
				<div>
					<div class="k-case-tree-item-association-title" v-html="association_title(ao.assoc)"></div>
				</div>
				<div v-if="editing_enabled(ao.assoc)" class="white k-remove-association-btn"><v-btn icon x-small color="#666" @click.stop="viewer.remove_association(ao.assoc)"><v-icon small>fas fa-times-circle</v-icon></v-btn></div>
			</div>
		</div>
		<div v-if="full_text_showing" style="overflow:auto">
			<div v-for="(framework, framework_identifier) in association_frameworks" :key="framework_identifier" class="k-case-tree-item-association-framework" :class="framework_css_class(framework_identifier)" :style="framework_css_style(framework_identifier)">
				<div class="k-case-tree-item-association-framework-title" :class="framework_color(framework_identifier)" :style="framework_color_style(framework_identifier)" @click="show_full_text_clicked=!show_full_text_clicked">
					<v-icon class="k-case-tree-item-association-icon">fas fa-arrows-alt-h</v-icon>
					<v-tooltip top><template v-slot:activator="{on}"><div v-on="on" class="k-case-tree-item-association-framework-title-inner" v-html="framework_title(framework)"></div></template>{{framework.document.title}}</v-tooltip>
					<v-spacer/>
					<v-btn v-if="show_full_text" x-small text class="k-tight-btn" color="#ccc"><v-icon small class="mr-1">fas fa-caret-up</v-icon>Contract</v-btn>
				</div>
				<div>
					<div v-for="(assoc) in framework.associations" :key="assoc.identifier" class="k-case-tree-item-association-full-text" :class="association_css_class(assoc, framework_identifier)" :style="full_text_showing ? U.framework_color_object(framework_identifier, 'border') : {}" @click.stop="association_clicked(assoc)">
						<v-icon class="k-case-tree-item-association-type-icon" small>{{association_type_icon(assoc)}}</v-icon>
						<div class="k-case-tree-item-association-type" v-html="association_type(assoc)"></div>
						<div>
							<div class="k-case-tree-item-association-title" v-html="association_title(assoc)"></div>
						</div>
						<v-spacer v-if="editing_enabled(assoc)" />
						<div v-if="editing_enabled(assoc)" class="white k-remove-association-btn"><v-btn icon x-small color="#666" @click.stop="viewer.remove_association(assoc)"><v-icon small>fas fa-times-circle</v-icon></v-btn></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'CASEItemAssociations',
	components: {},
	props: {
		item: { type: Object, required: true },
		framework_record: { type: Object, required: true },
		home_framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return {} }},
		show_full_text: { type: Boolean, required: false, default() { return false }},
		in_expanded_tile: { type: Boolean, required: false, default() { return false }},
		crosswalk: { type: Object, required: false, default() { return null }},
	},
	data() { return {
		show_full_text_clicked: false,
		association_frameworks: {},
		all_associations: [],
		suppressed_associations: [],
	}},
	computed: {
		...mapState(['association_type_labels', 'association_type_labels_reverse', 'association_type_icons', 'framework_records']),
		...mapGetters([]),

		item_is_document() { return empty(this.item.parent_node) },

		associations() {
			// look in cfo.displayed_associations_hash of the *home* framework for this item's non-isChildOf associations
			let arr = this.home_framework_record.cfo.displayed_associations_hash[this.item.cfitem.identifier]
			if (!arr) return []
			// remove any suppressed_associations (see below)
			let arrf = []
			for (let assoc of arr) {
				if (!this.suppressed_associations.includes(assoc)) arrf.push(assoc)
			}
			return arrf
		},

		full_text_showing() { return this.show_full_text && this.show_full_text_clicked },
	},
	watch: {
		associations: {immediate: true, handler(val) {
			this.get_association_frameworks()
		}},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		get_association_frameworks() {
			// organize associations by the framework of the other item; we use this to display the associations
			let hash = {}

			// always start with the framework for this item -- we want to show intra-framework associations first
			hash[this.framework_record.json.CFDocument.identifier] = {
				document: this.framework_record.json.CFDocument,
				associations: [],
			}

			for (let assoc of this.associations) {
				// get the associated framework for this assoc
				let aid = this.associated_item_data(assoc)
				let framework_doc

				// for rubrics...
				if (U.is_rubric_association(assoc)) {
					framework_doc = this.home_framework_record.json.CFDocument

				// look for the pattern / (:framework-identifier:)/ at the end of the associated_item_data's title
				} else if (aid.title.search(/\s*\(:(\S+):\)\s*$/) > -1) {
					// if we found it, this will be the identifier for the framework this item comes from
					let framework_identifier = RegExp.$1

					// if this is the home_framework_record, that's the framework we want
					if (framework_identifier == this.home_framework_record.lsdoc_identifier) {
						framework_doc = this.home_framework_record.json.CFDocument
					} else {
						// otherwise we can look that up in framework_record
						framework_doc = this.home_framework_record.cfo.associated_documents.find(x=>x.identifier == framework_identifier)
						if (!framework_doc) {
							framework_doc = this.framework_records.find(x=>x.lsdoc_identifier == framework_identifier).json.CFDocument
						}
					}

				} else {
					// if the associated item is part of the home framework, return it
					if (this.home_framework_record.cfo.cfitems[aid.identifier]) {
						framework_doc = this.home_framework_record.json.CFDocument
						
					} else {
						// try to look the identifier up in frameworks we've already loaded
						let fr = this.framework_records.find(x=>x.cfo && x.cfo.cfitems && x.cfo.cfitems[aid.identifier])
						if (fr) {
							framework_doc = fr.json.CFDocument
						} else {
							// ????? WE SHOULD NOW BE DOING THIS, AND RETRIEVING ANY NEEDED SOURCE FRAMEWORKS, FROM update_frameworks_with_associations,
							// so we should never need to load frameworks here

							// use U.get_lsdoc_identifier_from_item_identifier to try to figure out what document the item identifier came from;
							// this is asynchronous because it will use a service to look this up
							U.get_lsdoc_identifier_from_item_identifier(aid.identifier, assoc).then((lsdoc_identifier)=>{
								// found it, so try to get this framework's record
								let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)

								// if we don't know anything about this framework at all...
								if (!fr) {
									// it must be an association that wasn't created via satchel, right? or maybe an association to an item that has since been deleted?
									let aid = this.associated_item_data(assoc)
									console.log('CASEItemAssociations: couldn’t find associated item ' + aid.identifier)
									return ''
								}

								// since we're asynchronous here, we can't return a value...

								// if the framework hasn't yet been loaded, do so now
								if (!fr.framework_json_loaded && !fr.framework_json_load_failed && !fr.framework_json_loading) {
									// console.log('loading framework from association_frameworks fn')
									this.load_framework(lsdoc_identifier)
								} else {
									// insert new hash value if needed
									if (!this.association_frameworks[fr.lsdoc_identifier]) {
										this.$set(this.association_frameworks, fr.lsdoc_identifier, {
											document: fr.json.CFDocument,
											associations: [],
										})
									}

									// then add association
									this.association_frameworks[fr.lsdoc_identifier].associations.push(assoc)
								}

							}).catch((assoc)=>{
								console.log('CASEItemAssociations: Couldn’t identify the source of the original item', assoc)

								// OLD: add new 'unknown' framework if necessary, then add this assoc
								// now let's not show these; you can always see all associations in the table
								this.suppressed_associations.push(assoc)
								
								// if (!this.association_frameworks.xxx) {
								// 	this.$set(this.association_frameworks, 'xxx', {
								// 		document: { identifier: 'xxx', title: 'Unknown Framework' },
								// 		associations: [],
								// 	})
								// }
								// this.association_frameworks.xxx.associations.push(assoc)
								// // also add to all_associations
								// this.all_associations.push({assoc:assoc, framework_identifier: 'xxx'})
							})
						}
					}
				}

				if (framework_doc) {
					let framework_identifier = framework_doc.identifier

					// insert new hash value if needed
					if (!hash[framework_identifier]) hash[framework_identifier] = {
						document: framework_doc,
						associations: [],
					}

					// then add association
					hash[framework_identifier].associations.push(assoc)
				}
			}

			// if we didn't find any associations with the item's framework, remove that value from the hash
			if (hash[this.framework_record.json.CFDocument.identifier].associations.length == 0) {
				delete hash[this.framework_record.json.CFDocument.identifier]
			}

			// create list of all associations; we use this when showing just the "tiles"
			// also, if an item has a both isRelatedTo/exactMatchOf *and* a copiedFromSource, only show the copiedFromSource
			// note that we also reduce the association count that shows in CASEItem, for this same reason
			this.all_associations = []
			for (let fid in hash) {
				let new_arr = []
				for (let assoc of hash[fid].associations) {
					if (assoc.associationType == 'isRelatedTo' || assoc.associationType == 'exactMatchOf') {
						if (hash[fid].associations.find(x=>x.associationType == 'copiedFromSource' && x.originNodeURI.identifier == assoc.originNodeURI.identifier && x.destinationNodeURI.identifier == assoc.destinationNodeURI.identifier)) continue
					}
					new_arr.push(assoc)
					this.all_associations.push({assoc:assoc, framework_identifier: fid})
				}
				hash[fid].associations = new_arr
			}

			this.association_frameworks = hash
		},

		editing_enabled(assoc) { return this.viewer && this.viewer.association_removable && this.viewer.association_removable(assoc) },

		associated_item_data(assoc) {
			// either the origin or the destination must be this item; get the "uri" data for the other one
			// for 'aliasOf' assocs (which are 'fake'), we have to compare the titles against this node's tree_key
			if (assoc.associationType == 'aliasOf') {
				if (assoc.originNodeURI.title == this.item.tree_key) return assoc.destinationNodeURI
				else return assoc.originNodeURI

			} else {
				// else we go on the basis of identifiers
				if (assoc.originNodeURI.identifier == this.item.cfitem.identifier) return assoc.destinationNodeURI
				else return assoc.originNodeURI
			}
		},

		association_type_icon(assoc) {
			// this is a string for "bidirectional" assoc types, and an array for directional ones
			let abr = this.association_type_icons[assoc.associationType]
			if (typeof(abr) == 'string') return abr
			if (assoc.originNodeURI.identifier == this.item.cfitem.identifier) return abr[0]
			else return abr[1]
		},

		association_type(assoc) {
			// use association_type_labels or association_type_labels_reverse depending on whether this item is the origin or destination of the association
			if (assoc.originNodeURI.identifier == this.item.cfitem.identifier) {
				return this.association_type_labels[assoc.associationType]
			} else {
				return this.association_type_labels_reverse[assoc.associationType]
			}
		},

		association_line_marker(assoc) {
			// this is used to target the line from an association on the left to the associated item on the right
			let aid = this.associated_item_data(assoc)
			let s = this.item.tree_key + '-' + assoc.associationType.replace(/:/, '--') + '-' + aid.identifier
			// have to add the tree_key for the 'fake' aliasOf assocs
			if (assoc.associationType == 'aliasOf') s += '-' + aid.title
			return s
		},

		association_framework(assoc) {
			for (let lsdoc_identifier in this.association_frameworks) {
				if (this.association_frameworks[lsdoc_identifier].associations.includes(assoc)) {
					return this.association_frameworks[lsdoc_identifier].document
				}
			}
			// if not found, return empty string
			return ''
		},

		framework_title(framework) {
			// if we're showing the full text, we have more space
			let max_len = 34
			if (this.full_text_showing) max_len = 74

			let s = framework.document.title.substr(0,max_len)
			if (s != framework.document.title) s += '…'
			return s
		},

		framework_css_class(framework_identifier) {
			if (!this.full_text_showing) return ''	//  this.framework_color(framework_identifier) + ' ' + this.framework_border_color(framework_identifier)
			else return this.framework_border_color(framework_identifier) + ' k-case-tree-item-association-framework-full-text'
		},

		framework_css_style(framework_identifier) {
			return U.framework_color_object(framework_identifier, 'border')
		},

		framework_color_style(framework_identifier) {
			return U.framework_color_object(framework_identifier, 'dark')
		},

		framework_border_color(framework_identifier) {
			const framework_color = U.framework_color(framework_identifier)
			if (!isNaN(framework_color)) 'k-framework-color-' + framework_color + '-border'
			return ''
		},

		framework_color(framework_identifier) {
			// return U.framework_color(framework_identifier) + '-lighten-4'
			const framework_color = U.framework_color(framework_identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-dark'
			return ''
		},

		association_css_class(assoc, framework_identifier) {
			if (!this.full_text_showing) {
				let aid = this.associated_item_data(assoc)
				if (aid.identifier == this.viewer.last_clicked_association_identifier) {
					if (assoc.associationType != 'aliasOf') {
						return this.framework_color(framework_identifier) + ' k-case-tree-item-association-last-clicked elevation-3'
					} else {
						// for aliasOf, we have to also check against last_clicked_association_item_tree_key...
						if (aid.title == this.viewer.last_clicked_association_item_tree_key) {
							return this.framework_color(framework_identifier) + ' k-case-tree-item-association-last-clicked elevation-3'
						}
					}
				}
				return this.framework_color(framework_identifier)
			}
			return this.framework_border_color(framework_identifier)
		},

		association_title(assoc) {
			let s
			// if we have the associated framework in memory, get the html from there
			let aid = this.associated_item_data(assoc)
			let afd = this.association_framework(assoc)	// this will be a CFDocument (or something similar at least)
			if (!empty(afd)) {
				let af = this.framework_records.find(x=>x.lsdoc_identifier == afd.identifier)
				if (af) {
					if (af.framework_json_loaded) {
						// the cfo might take a few ticks to be processed after the framework is loaded
						if (af.cfo) {
							// for rubrics, we need to look up the rubric
							if (U.is_rubric_association(assoc)) {
								let rubric = U.get_rubric_from_association(assoc, af)
								if (rubric) {
									// we use the title of the rubric itself for all rubric association types
									if (!this.full_text_showing) {
										s = rubric.title.substr(0, 9)
										if (s != rubric.title) s += '…'
									} else {
										s = rubric.title
									}
								}

							// else for items...
							} else {
								let item = af.cfo.cfitems[aid.identifier]
								if (item) {
									// if !full_text_showing...
									if (!this.full_text_showing) {
										// if we have a humanCodingScheme, just use it
										if (item.humanCodingScheme) s = sr('<b>$1</b>', item.humanCodingScheme)
										else {
											// otherwise use the first 9 chars
											s = item.fullStatement.substr(0, 9)
											if (s != item.fullStatement) s += '…'
										}
									} else {
										if (item.humanCodingScheme) s = sr('<b>$1</b> $2', item.humanCodingScheme, item.fullStatement)
										else s = item.fullStatement
									}
								}
							}
						}
					} else if (!af.framework_json_loaded && !af.framework_json_load_failed && !af.framework_json_loading) {
						console.log('load_framework2: ' + af.lsdoc_identifier)
						this.load_framework(afd.identifier)
					}
				}
			}

			if (empty(s)) {
				// get the item from the association that isn't this item; then take out the framework pattern (see above) if there
				s = aid.title.replace(/\s*\(:(\S+):\)\s*$/, '')

				// if we still don't have an s, return 'unknown'
				if (empty(s)) {
					if (this.full_text_showing) return 'Unknown item'
					else return 'Unknown'
				}
				
				// only use first 9 chars if full text isn't showing
				if (!this.full_text_showing) {
					let temp = s
					s = s.substr(0, 9)
					if (s != temp) s += '…'
				}
			}

			// render latex and markdown if full text is showing
			if (this.full_text_showing) {
				s = U.marked_latex(s)
			}
			return s
		},

		show_associated_item_in_expanded_tile(assoc) {
			let cfdoc = this.association_framework(assoc)
			if (cfdoc) {
				let framework_record = this.framework_records.find(x=>x.lsdoc_identifier == cfdoc.identifier)
				if (framework_record) {
					let item = framework_record.cfo.cfitems[this.associated_item_data(assoc).identifier]
					if (item) {
						vapp.case_tree_component.show_expanded_item(item.tree_nodes[0], framework_record)
						return
					}
				}
			}
			// if we get to here something went wrong...
			console.log('couldn’t process association_clicked', assoc)
		},

		association_clicked(assoc) {
			if (CFAssociation.is_nomatch_assoc(assoc)) {
				this.$alert('This item has been designated as not having a match in the crosswalked framework.')
				return
			}

			if (this.crosswalk) {
				this.crosswalk.show_right_item_in_tree(this.associated_item_data(assoc).identifier)
				return
			}

			// if we're in the expanded tile, switch to showing the associated item in the expanded tile
			if (this.in_expanded_tile || this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
				this.show_associated_item_in_expanded_tile(assoc)
				return
			}

			// if we're not in the expanded tile, we can't show the associated framework on a phone
			if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return

			// for a rubric, tell the tile to show the item's associated rubric
			if (U.is_rubric_association(assoc)) {
				this.$emit('show_rubric_from_association', assoc)
				return
			}

			// if we're in the viewer context, reveal the association
			if (this.viewer.toggle_associated_item) {
				// for most associations, we send in the associated identifier, but for aliases...
				if (assoc.associationType != 'aliasOf') {
					this.viewer.toggle_associated_item(assoc.associationType, this.item, this.association_framework(assoc).identifier, this.associated_item_data(assoc).identifier)

					// and if the associationType is exemplar, show it in the expanded tile, because the "examplar" might be in the supplementalNotes, which won't show up in the tree view on the right.
					if (assoc.associationType == 'exemplar') {
						this.show_associated_item_in_expanded_tile(assoc)
					}

				} else {
					// if we're viewing on the left the origin node of the assoc (a “copy”), show on the right the destination (the “original”)
					// (note that we stash the tree_key in the fake association's title)
					if (assoc.originNodeURI.title == this.item.tree_key) {
						this.viewer.toggle_associated_item(assoc.associationType, this.item, this.association_framework(assoc).identifier, assoc.destinationNodeURI.identifier, assoc.destinationNodeURI.title*1)

					} else {
						// else we're either:
						// a) viewing on the left the destination node of the assoc (the “original”), so show on the right the origin (the “copy”)
						// or b) not viewing either the destination or the origin, in which case show on the right the other copy
						// either way, we show the origin tree_key
						this.viewer.toggle_associated_item(assoc.associationType, this.item, this.association_framework(assoc).identifier, assoc.originNodeURI.identifier, assoc.originNodeURI.title*1)
					}
				}

			} else {
				// otherwise we're probably viewing the association in the full-screen tile; call switch_associated_item to change to viewing the clicked item on the right side
				vapp.case_tree_component.switch_associated_item(this.association_framework(assoc).identifier, this.associated_item_data(assoc).identifier)
			}
		},

		load_framework(lsdoc_identifier) {
			// first load the framework from the server
			U.loading_start('Loading framework…')
			this.$store.dispatch('get_lsdoc', lsdoc_identifier).then(()=>{
				U.loading_stop()

				// then build the cfo for the framework
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
				U.build_cfo(this.$worker, fr.json).then((cfo)=>{
					this.$store.commit('set', [fr, 'cfo', cfo])
					this.$store.commit('set', [fr, 'framework_json_loading', false])
					U.loading_stop()

					// after loading a framework, re-call get_association_frameworks to re-form the list of frameworks/associations to show
					this.get_association_frameworks()
				})
				.catch((e)=>{
					this.$store.commit('set', [fr, 'framework_json_load_failed', true])
					U.loading_stop()
					console.log(e)
				})

			}).catch((e)=>{
				console.log(e)
				U.loading_stop()
				// fail silently, but set framework_json_load_failed to true so we don't keep trying to load
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
				if (fr) this.$store.commit('set', [fr, 'framework_json_load_failed', true])
			})
		},
	}
}
</script>

<style lang="scss">
.k-case-tree-item-associations-condensed {
	overflow:auto;
	white-space:nowrap;
}

.k-case-tree-item-tile .k-case-tree-item-associations {
	font-size:14px;
	line-height:18px;

	margin-top:8px;
	padding-top:6px;
	border-top:1px solid #ccc;
	text-align: left;
}

.k-case-tree-item .k-case-tree-item-associations {
	margin:4px 4px 4px 4px;
	font-size:12px;
	line-height:15px;

	.k-case-tree-item-associations-condensed {
		flex-wrap:wrap;
		justify-content:center;
	}

	.k-remove-association-btn {
		margin:-2px -4px -2px 4px;
		padding:0 2px;
	}

	.k-case-tree-item-associations-show-btn {
		display:none;
	}
}

.k-case-tree-item-association-framework {
	display:inline-block;
}

.k-case-tree-item-association-framework-full-text {
	border:1px solid #ccc;
	background-color:#ccc;
	border-radius:6px;
	margin:4px 4px 0 4px;
	background-color:#fff;
	padding:0;
	display:block;
	margin-bottom:6px;
	.k-case-tree-item-association {
		background-color:transparent;
		font-size:12px;
	}
}

.k-case-tree-item-association-icon {
	font-size:18px!important;
	padding:0 8px 0 6px;
	color:#fff!important;
}

.k-case-tree-item-association-framework-title {
	font-weight:bold;
	color:#fff;
	border-radius:6px 6px 0 0;
	margin:-1px;
	cursor:pointer;
	display:flex;
	align-items:flex-start;
	text-align:left;
	padding:3px 0 3px 3px;
	font-size:0.85em;
	line-height:1.2em;
}

.k-case-tree-item-association-framework-title-inner {
	margin-top:3px;
}

.k-case-tree-item-association-type-icon {
	margin-right:4px;
	color:#fff!important;
	font-size:12px!important;
	margin-top:2px;
}

.k-case-tree-item-association-condensed {
	display:inline-flex;
	align-items:flex-start;
	justify-content: flex-start;
	text-align:left!important;
	// margin-top:4px;
	color:#fff;
	cursor:pointer;
	white-space:nowrap;
	background-color:#fff;
	border:2px solid transparent;
	border-radius:6px;
	margin:3px;
	padding:2px 4px 2px 4px;
	font-size:9px;

	.k-remove-association-btn {
		margin:-2px -3px 0 4px;
		padding:0 2px;
		border-radius:4px;
	}
}

.k-case-tree-item-association-full-text {
	cursor:pointer;
	display:inline-flex;
	align-items:flex-start;
	justify-content: flex-start;
	text-align:left!important;
	width: 100%;
	font-size:12px;
	line-height:16px;
	border-top:1px solid #ccc;
	padding-top:4px;
	padding-bottom:4px;
	.k-case-tree-item-association-type-icon {
		color:#666!important;
		margin-left:6px;
		margin-right:6px;
	}
	.k-remove-association-btn {
		margin:0 4px;
		// padding:0 2px;
		// border-radius:3px;
	}
}

.k-case-tree-item-association-type {
	font-weight:bold;
	color:#666;
	margin-right:8px;
	white-space:nowrap;
}

.k-case-tree-item-association-last-clicked {
	border-color:$v-amber-darken-4!important;
}

.k-case-tree-item-association-title {
	// see also k-case-tree-item-statement-abbreviated in CASEItem
	p:first-of-type {
		display:inline;
	}

	ul, ol {
		margin-top:4px;
	}
}
</style>

<template>
	<v-dialog v-model="dialog_open" scrollable persistent>
		<v-card id="k-rubric-dialog-card" style="display: flex; flex-direction: column; gap: 0px">
			<v-card-title class="k-rubric-dialog-title pb-2 pt-2" style="border-bottom:1px solid #ccc">
				<b><v-icon color="primary" class="mr-3">fas fa-ballot-check</v-icon>{{ title_text }}</b>
				<v-spacer/>
				<div v-if="!editing_on">
					<v-menu :open-on-hover="false" :transition="false" bottom left><template v-slot:activator="{on}"><v-btn v-on="on" icon color="#222" class="ml-2"><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
						<v-list dense>
							<v-list-item v-if="editing_enabled" @click="start_editing"><v-list-item-icon><v-icon small>fas fa-edit fa-fw</v-icon></v-list-item-icon><v-list-item-title>Edit Rubric</v-list-item-title></v-list-item>
							<v-list-item @click="print_rubric"><v-list-item-icon><v-icon small>fas fa-print fa-fw</v-icon></v-list-item-icon><v-list-item-title>Print Rubric</v-list-item-title></v-list-item>
							<v-list-item @click="export_rubric"><v-list-item-icon><v-icon small>fas fa-file-export fa-fw</v-icon></v-list-item-icon><v-list-item-title>Export Rubric</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small fab color="#222" dark @click="$emit('dialog_cancel')"><v-icon>fas fa-xmark</v-icon></v-btn></template>Close rubric</v-tooltip>
				</div>

				<div v-else>
					<v-btn v-if="!is_new_rubric" small class="mr-1" color="red darken-3" dark @click="delete_rubric"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Rubric</v-btn>
					<v-btn v-if="is_new_rubric" small class="mr-1" color="primary" dark @click="import_rubric"><v-icon small class="mr-2">fas fa-file-import</v-icon>Import Rubric</v-btn>
					<template v-if="isStateChanged">
						<v-btn class="mr-1" small color="#222" dark @click="cancel_editing"><v-icon small class="mr-2">fas fa-xmark</v-icon>Cancel</v-btn>
						<v-btn small @click="save_rubric" color="primary" :disabled="!title"><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</v-btn>
					</template>
					<template v-else-if="is_new_rubric">
						<v-btn small color="#222" dark @click="cancel_editing"><v-icon small class="mr-2">fas fa-xmark</v-icon>Cancel</v-btn>
					</template>
					<template v-else="is_new_rubric">
						<v-btn small @click="cancel_editing" color="primary"><v-icon small class="mr-2">fas fa-check</v-icon>Done Editing</v-btn>
					</template>
				</div>
			</v-card-title>
			<v-card-text class="k-rubric-dialog-content" :class="editing_on?'pr-1':'pr-0'">
				<!-- if editing, show form values -->
				<div v-if="editing_on" class="d-flex pt-2 pb-4" style="gap: 16px">
					<div style="flex:1;">
						<div class="k-case-ie-line-label k-rubric-editor-required">Rubric Title:</div>
						<v-text-field background-color="#fff" clearable outlined dense hide-details="auto" :rules="[rules.required]" v-model="title" />
					</div>
					<div style="flex:1">
						<div class="k-case-ie-line-label">Rubric Description:</div>
						<v-textarea background-color="#fff" outlined dense hide-details rows="1" v-model="description" auto-grow clearable />
					</div>
					<div style="flex:1">
						<div class="k-case-ie-line-label">Rubric Notes:</div>
						<v-textarea background-color="#fff" outlined dense hide-details rows="1" v-model="notes" auto-grow clearable />
					</div>
				</div>

				<!-- else show description and notes if present -->
				<div v-if="!editing_on&&!is_new_rubric" class="d-flex">
					<div v-if="rubric.description" class="mt-2 mx-2 pa-2" style="flex:0 1 50%; background-color:#eee; border-radius:6px;">
						<div><b>Rubric Description:</b></div>
						<div v-html="rubric.description"></div>
					</div>
					<div v-if="rubric.notes" class="mt-2 mx-2 pa-2" style="flex:0 1 50%; background-color:#eee; border-radius:6px;">
						<div><b>Rubric Notes:</b></div>
						<div v-html="rubric.notes"></div>
					</div>
				</div>

				<CFRubricGrid v-model="criteria" :rubric_identifier="rubric_identifier" :initial_rubric_component_identifier_showing="initial_rubric_component_identifier_showing" :framework_record="framework_record" :editing_on="editing_on" />
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import CFRubricGrid from './CFRubricGrid.vue'

const DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_COUNT = 4
const DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_QUALITIES = ['Minimally Proficient', 'Partially Proficient', 'Proficient', 'Highly Proficient']

export default {
	components: {CFRubricGrid},
	props: {
		initiating_cfitem: { type: Object, required: true },
		rubric_identifier: { type: String, required: true },
		framework_record: { type: Object, required: true },
		initial_rubric_component_identifier_showing: { type: String, required:false, default() { return ''}}
	},
	data() {return {
		dialog_open: true,
		editing_on: false,
		title: "",
		description: "",
		notes: "",
		criteria: [],
		initialState: {
			title: "",
			description: "",
			notes: "",
			criteria: [],
		},
		rules: {
			required: value => !!value || 'Field is required',
		},
	}},
	computed: {
		// get rubric from rubric_entity_hash; if not there, it's a new rubric
		rubric() { return this.framework_record.cfo.rubric_entity_hash[this.rubric_identifier] },
		is_new_rubric() { return empty(this.rubric) },

		// the association tying the rubric (not criteria) to a CFItem. if this is a new rubric it won't yet exist, and it may not exist ever (rubrics shouldn't be required to be tied to cfitems)
		rubric_association() {
			if (this.is_new_rubric) return null
			// for now we make the simplifying assumption that a rubric can only be associated to a single cfitem
			return this.framework_record.json.CFAssociations.find(x=>x.associationType == 'ext:hasRubric' && x.destinationNodeURI.identifier == this.rubric_identifier)
		},

		// title for the dialog: either the rubric title if it's already created, or "New Rubric" otherwise
		title_text: function() { return this.is_new_rubric ? 'New Rubric (BETA)' : this.rubric.title },

		// reference to the currently-active CASEFrameworkViewer, if there is one (there probably always should be if we're showing a rubric)
		viewer() { return vapp.case_tree_component },
		editing_enabled() { return this.viewer?.editing_enabled },

		item_identifier() { return this.initiating_cfitem.identifier },

		isStateChanged() {
			return this.title !== this.initialState.title 
				|| this.description !== this.initialState.description
				|| this.notes !== this.initialState.notes
				|| JSON.stringify(this.criteria) !== JSON.stringify(this.initialState.criteria)
		}
	},
	mounted() {
		vapp.rubric_modal_component = this

		// if we already have a rubric for the incoming rubric_identifier, establish editable properties from it
		if (this.framework_record.cfo.rubric_entity_hash[this.rubric_identifier]) {
			this.title = this.rubric.title;
			this.description = this.rubric.description
			this.notes = this.rubric.notes
			// note that we use an object_copy here
			this.criteria = object_copy(this.rubric.CFRubricCriteria)

		// else establish default properties for the new rubric
		} else {
			// note that the including component (currently CASEItemTile) should mint a new rubric_identifier for new rubrics
			this.title = 'New Rubric';
			this.description = ''
			this.notes = ''

			// establish a default criterion and levels
			let rc = new CFRubricCriterion()
			rc.position = 1
			rc.rubricId = this.rubric_identifier
			rc.weight = 1
			rc.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri
			
			for (let i = 0; i < DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_COUNT; i++) {
				let rcl = new CFRubricCriterionLevel()
				// use default qualities defined above; this could be made configurable from instance to instance
				rcl.quality = DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_QUALITIES[i]
				rcl.score = i + 1
				rcl.position = i + 1
				rcl.rubricCriterionId = rc.identifier
				rcl.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri
				rc.CFRubricCriterionLevels.push(rcl)
			}

			// use the json version for consistency with what we do when this is an existing rubric
			this.criteria = [rc.to_json()]

			// we must be in editing mode if it's a new rubric
			this.editing_on = true
		}

		this.set_initial_state()
	},
	methods: {
		set_initial_state() {
			this.initialState = {
				title: this.title,
				description: this.description,
				notes: this.notes,
				criteria: JSON.parse(JSON.stringify(this.criteria))
			}
		},

		start_editing() {
			this.editing_on = true
		},

		cancel_editing() {
			// if the rubric was never saved, close the dialog; else turn editing off
			if (this.is_new_rubric) this.$emit('dialog_cancel')
			else this.editing_on = false
		},

		save_rubric() {
			// create rubric object

			// trim all descriptions and other text-based inputs before saving
			for (let rc of this.criteria) {
				rc.description = $.trim(rc.description)
				rc.notes = $.trim(rc.notes)
				for (let rcl of rc.CFRubricCriterionLevels) {
					rcl.description = $.trim(rcl.description)
					rcl.quality = $.trim(rcl.quality)
					rcl.feedback = $.trim(rcl.feedback)
				}
			}

			let rubric = new CFRubric({
				identifier: this.rubric_identifier,
				title: $.trim(this.title),
				description: $.trim(this.description),
				notes: $.trim(this.notes),
				CFRubricCriteria: this.criteria
			})

			// complete the rubric, filling in uri and lastChangeDateTime -- lastChangeDateTime will be set to *NOW*, whether this is a new rubric or an existing rubric we're updating, which is what we want
			rubric.complete_data(this.framework_record.json.CFDocument)

			// start constructing the payload for save_framework_data
			let payload = { lsdoc_identifier: this.framework_record.lsdoc_identifier }

			let association = null
			if (this.is_new_rubric) {
				payload.CFRubrics = [rubric.to_json()]	// for a new rubric, we use to_json, not to_json_for_update

				// create association linking rubric to starting item, if it's a new rubric; otherwise we don't need to send the association in to save_framework_data
				// TODO: for now we assume we always have a starting cfitem; later we may want to support rubric creation when this is not the case
				association = new CFAssociation({
					originNodeURI: {
						title: U.generate_cfassociation_node_uri_title(this.initiating_cfitem),
						identifier: this.initiating_cfitem.identifier,
						uri: this.initiating_cfitem.uri
					},
					associationType: "ext:hasRubric",
					destinationNodeURI: {
						title: this.title,
						identifier: rubric.identifier,
						uri: rubric.uri
					}
				})
				// complete the association, filling in identifier, uri and lastChangeDateTime; then add to payload
				association.complete_data(this.framework_record.json.CFDocument)
				payload.CFAssociations = [association.to_json()]

			} else {
				// for updating an existing rubric, we don't need to change the association
				payload.CFRubrics = [rubric.to_json_for_update()]
			}

			// console.log(payload); return;

			this.$store.dispatch('save_framework_data', payload).then(response => {
				// once the save_framework_data service completes successfully, we need to update the framework's data model in the $store

				// update timestamps with the value sent back from the server, then update or push to store...
				// for now at least (12/5/2024), the server will update the dates of both the rubric and all criteria and levels on every save
				rubric.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
				for (let rc of rubric.CFRubricCriteria) {
					rc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					for (let rcl of rc.CFRubricCriterionLevels) {
						rcl.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}
				}

				// get new rubric json
				let rubric_json = rubric.to_json()

				// whether the rubric was new or updated, add/update the rubric an all its sub-entities in rubric_entity_hash. (it's important to do this before the other changes below, but as soon as we do this, is_new_rubric will change...)
				let is_new_rubric = this.is_new_rubric
				U.update_rubric_entity_hash(rubric_json, this.framework_record)

				if (is_new_rubric) {
					// push rubric json to store
					this.$store.commit('set', [this.framework_record.json.CFRubrics, 'PUSH', rubric_json])

					// push association json to store if we saved one
					if (association) {
						association.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
						let assoc_json = association.to_json()
						this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', assoc_json])

						// update cfo.associations_hash
						U.update_associations_hash(this.framework_record.cfo, assoc_json)
						// update associations showing in the tree
						this.viewer.update_association_display({framework_id: this.framework_record.lsdoc_identifier, assoc: assoc_json, actions: ['add', 'display']})
					}

					// TODO: tell the enclosing component to re-open?? maybe we don't have to

				} else {
					// splice updated rubric json to store
					let i = this.framework_record.json.CFRubrics.findIndex(x=>x.identifier == rubric.identifier)
					this.$store.commit('set', [this.framework_record.json.CFRubrics, 'SPLICE', i, rubric.to_json()])
				}

				// reset the initial state
				this.set_initial_state()                
			})
		},

		tabber(event) {
			U.tabber(event)
		},

		delete_rubric() {
			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to delete this Rubric?',
				acceptText: 'Delete',
				acceptColor: 'red darken-2',
			}).then(y => {
				const payload = {
					lsdoc_identifier: this.framework_record.lsdoc_identifier,
					CFRubrics: [{
						identifier: this.rubric_identifier,
						delete: 'yes',
					}],
					CFAssociations: [{
						identifier: this.rubric_association.identifier,
						delete: 'yes',
					}]
				}

				// TODO (TC): if there are any associations to criteria and/or levels of this rubric, those associations need to be removed too

				this.$store.dispatch('save_framework_data', payload).then(response => {
					// once the save_framework_data service completes successfully, we need to update the framework's data model in the $store

					let temp_rubric_association = this.rubric_association
					
					// update rubric_entity_hash
					U.delete_from_rubric_entity_hash(this.rubric, this.framework_record)

					// remove the association for the rubric
					U.remove_from_associations_hash(this.framework_record.cfo, temp_rubric_association)
					U.delete_association_from_json(this.framework_record, temp_rubric_association.identifier)
					// TODO (TC): also remove associations to criteria and/or levels from CFAssociations

					// update associations showing in the tree
					this.viewer.update_association_display({framework_id: this.framework_record.lsdoc_identifier, actions: ['remove']})

					// remove rubric from CFRubrics
					const rubric_index = this.framework_record.json.CFRubrics.findIndex(x=>x.identifier == this.rubric_identifier)
					if (rubric_index > -1) this.$store.commit('set', [this.framework_record.json.CFRubrics, 'SPLICE', rubric_index])					// should always be true

					// kill dialog
					this.$emit('dialog_cancel')
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		print_rubric() {
			this.$alert('This functionality is coming soon...')
		},
		
		export_rubric() {
			this.$alert('This functionality is coming soon...')
		},

		import_rubric() {
			this.$alert('This functionality is coming soon...')
		},

	},
}
</script>

<style scoped lang="scss">

#k-rubric-dialog-card {
	background-color: white !important;
	font-size:16px;
	line-height:21px;

	.k-rubric-dialog-title {
		.k-editor-title {
			width: 100%;
		}
	}

	.k-rubric-dialog-content {
		.spacer {
			height: 32px;
			width: 100%;
		}
	}
}
.k-rubric-editor-required::after {
	content: "*";
	color: red;
}
</style>

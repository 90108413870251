<template><div>
<div class="k-case-tree-item-tile-outer" :class="item_outer_css()" @click.stop="viewer.search_panel_to_back" @mouseover="mouseover" @mouseout="mouseout">
	<div class="k-case-tree-item-tile" :class="item_css()">
		<div v-if="!ss_framework_data.ignore_status_dates" class="k-case-tree-item-not-yet-adopted-message">This {{item_is_document?'framework':'item'}} will be officially <b>implemented</b> as of {{item.cfitem.statusStartDate}}.</div>
		<div v-if="!ss_framework_data.ignore_status_dates" class="k-case-tree-item-scheduled-no-longer-adopted-message">This {{item_is_document?'framework':'item'}} will be <b>retired</b> as of {{item.cfitem.statusEndDate}}.</div>
		<div v-if="!ss_framework_data.ignore_status_dates" class="k-case-tree-item-no-longer-adopted-message">This {{item_is_document?'framework':'item'}} has been <b>retired</b> as of {{item.cfitem.statusEndDate}}.</div>
		
		<v-btn v-if="expanded_tile" class="k-case-tree-item-tile-close-btn" icon outlined small @click.stop="$emit('full_screen_compressed')"><v-icon small color="grey darken-2">fas fa-compress</v-icon></v-btn>
		<v-btn v-if="!inline&&!pinned_tile&&!expanded_tile" class="k-case-tree-item-tile-close-btn" small icon color="grey darken-2" @click.stop="close_tile"><v-icon>fas fa-times-circle</v-icon></v-btn>
		<v-btn v-if="!inline&&pinned_tile" class="k-case-tree-item-tile-close-btn" small icon color="red" @click.stop="close_tile"><v-icon small>fas fa-thumbtack</v-icon></v-btn>

		<div v-if="!item_is_from_home_framework" class="k-case-tree-item-tile-framework-header" :class="item_framework_class" :style="item_framework_style" v-html="item_framework_description"></div>

		<div class="k-case-tree-item-tile-inner">
			<span v-if="show_chooser_btn"><v-hover v-slot:default="{hover}"><v-btn style="margin:-1px 1px 0 1px;" icon x-small :color="chooser_color(hover)" @click.stop="show_chooser_fn_clicked"><v-icon :style="chooser_style(hover)">{{chooser_icon(hover)}}</v-icon></v-btn></v-hover></span>
			<v-icon v-if="selected_items&&!show_chooser_btn" :style="selected_item_icon_style" color="primary">{{item_is_selected?'fas fa-play':'far fa-circle'}}</v-icon>

			<v-btn v-if="item_is_document" class="k-case-tree-item-node-btn" x-small icon color="grey darken-2"><v-icon small>fas fa-map</v-icon></v-btn>
			<v-btn v-if="!item_is_document && item.children.length>0" class="k-case-tree-item-node-btn" :style="node_btn_style" x-small icon color="grey darken-2" @click.stop="item_clicked"><v-icon small>fas fa-chevron-circle-right</v-icon></v-btn>
			<v-btn v-if="!item_is_document && item.children.length==0" class="k-case-tree-item-node-btn" small icon color="grey darken-2" @click.stop="item_clicked"><v-icon small>fas fa-dot-circle</v-icon></v-btn>

			<v-btn v-if="tracking_changes" class="k-case-tree-item-node-btn k-case-tree-item-track-changes-btn" style="cursor:default" x-small icon @click.stop="tracked_change_clicked"><v-icon small>fas {{tracked_change_icon}}</v-icon></v-btn>

			<div class="k-case-tree-item-code-plus-statement">
				<v-btn v-if="!expanded_tile" class="float-right" v-show="(!pinned_tile||!framework_record.active_node)&&!$vuetify.breakpoint.xs" :style="pinned_tile?'margin-top:-10px;margin-right:-2px;':''" small icon color="grey darken-2" @click="expand_tile"><v-icon small>fas fa-expand</v-icon></v-btn>
				<v-btn v-if="!inline&&!pinned_tile&&!item_is_document&&item_is_from_home_framework&&!$vuetify.breakpoint.xs" class="float-right" style="margin-top:1px" small icon :color="item_is_pinned?'red':'green'" @click="toggle_pin"><v-icon small>fas fa-thumbtack</v-icon></v-btn>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!inline&&!pinned_tile&&is_derived_item&&!$vuetify.breakpoint.xs" class="float-right" small icon color="deep-purple darken-2" @click="derived_info_showing=true"><v-icon small>fas fa-arrow-up-from-bracket</v-icon></v-btn></template><div class="text-center">This item is derived<br>from the source framework.<br>Click for info</div></v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!inline&&!pinned_tile&&item_is_document&&ss_framework_data.is_mirror=='yes'&&user_can_edit&&!$vuetify.breakpoint.xs" class="float-right" style="margin-top:1px" small icon color="primary" @click="mirror_info_showing=true"><v-icon small>fas fa-clone</v-icon></v-btn></template><div class="text-center">This framework is “mirrored”.<br>Click for info</div></v-tooltip>
				<div>
					<span v-if="stats_mode" @mouseover="stats_mouseover" @mouseout="stats_mouseout" @click.stop="stats_clicked">
						<b class="green--text" v-if="item.cfitem.stats.descendent_count>1">[{{item.cfitem.stats.descendent_count}}]</b>
						<b class="red--text" style="font-size:10px" v-if="item.cfitem.stats.items_removed>0">–{{item.cfitem.stats.items_removed}}</b>
						<span v-if="item.cfitem.stats.is_duplicate_of"><v-icon x-small color="green">fas fa-circle</v-icon></span>
						<span v-if="item.cfitem.stats.possible_related_to&&!item.cfitem.stats.is_duplicate_of"><v-icon x-small color="red">far fa-circle</v-icon></span>
						<span v-if="item.cfitem.stats.add_is_child_of_to_duplicate"><v-icon x-small color="red">fas fa-dot-circle</v-icon></span>
						<span v-if="item.cfitem.stats.to_be_deleted"><v-icon x-small color="red">fas fa-circle</v-icon></span>
					</span>

					<span @click.stop="item_clicked">
						<b v-if="item.cfitem.humanCodingScheme!=null" class="k-case-tree-item-statement-hcs mr-1 grey--text text--darken-2" v-html="human_coding_scheme"></b>
						<div class="k-case-tree-item-statement k-case-tree-markdown" v-html="marked_full_statement"></div>
						<span>
							<CopyBtn v-if="!inline&&!pinned_tile" :size="($vuetify.breakpoint.xs||$vuetify.breakpoint.sm)?'small':''" :val="tree_statement_for_copy()" color="#666"/>
							<!-- when tile is inline, don't show the copy btn; instead show the expand button - user can go to more info and copy from there -->
							<v-btn v-if="inline" x-small icon @click.stop="expand_tile" style="height:18px;margin-top:-2px;"><v-icon small color="#666">fas fa-expand</v-icon></v-btn>
							<v-btn v-if="embedded_mode&&item_is_node&&$vuetify.breakpoint.xs" x-small icon @click.stop="limit_search_to_this_item=!limit_search_to_this_item" style="height:18px;margin-top:-2px;"><v-icon small :color="(limit_search_to_this_item&&search_limit_to_item)?'primary':'#777'">fas fa-search</v-icon></v-btn>
						</span>
					</span>
				</div>
				<div v-if="!item_is_document&&node_is_open&&progression_table_available" class="my-1"><v-btn x-small outlined color="primary" @click.stop="show_progression_table"><v-icon small class="mx-2">fas fa-forward</v-icon> Show Progression</v-btn></div>

				<div v-if="viewer.public_review_on&&!inline&&!pinned_tile&&!expanded_tile" class="k-case-tree-item-public-review-description d-flex align-center">
					<v-btn icon small @click="U.show_help('public_review_comments')" color="light-blue"><v-icon small>fas fa-info-circle</v-icon></v-btn>
					<div class="mr-1" v-html="ss_framework_data.public_review_description?ss_framework_data.public_review_description:'This framework is open for public review.'"></div>
					<v-spacer/>
					<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#333" class="mr-1" :outlined="(signed_in||viewer.public_review_on)&&viewer.show_comments" @click="viewer.toggle_show_comments"><v-icon style="font-size:12px">fas fa-comment</v-icon></v-btn></template>{{viewer.show_comments?'Hide public review comments':'Show public review comments'}}</v-tooltip>
				</div>
				<div v-if="show_tile_comments_component" class="k-tile-comments-wrapper"><TileComments :viewer="viewer" :item="item" :framework_record="framework_record" :item_identifier="item.cfitem.identifier" /></div>
				<div v-if="false" style="margin-top:-32px; margin-left:-20px;"><v-btn small color="red darken-3" class="k-tight-btn" dark @click="open_learning_check"><v-icon small class="mr-2">fas fa-bank</v-icon>Velocity Bank</v-btn></div>

				<div v-if="inline && !item_is_document && (show_identifiers_in_tiles || show_associations)" class="k-case-tree-item-details text-center mt-2">
					<div v-if="show_identifiers_in_tiles"><span class="k-case-tree-item-details-label">Identifier:</span> {{item.cfitem.identifier}}<CopyBtn :size="'small'" color="#666" :val="item.cfitem.identifier" class="ml-1"/></div>
					<CASEItemAssociations v-if="show_associations" :item="item" :home_framework_record="framework_record" :framework_record="framework_record" :viewer="viewer" :show_full_text="true" />
				</div>

				<div v-if="inline&&(marked_notes||marked_exemplar)&&!($vuetify.breakpoint.xs||$vuetify.breakpoint.sm)" class="text-center">
					<v-btn v-if="marked_notes||marked_exemplar" x-small color="#666" class="ma-1" text @click="show_notes_and_exemplar=!show_notes_and_exemplar">{{show_notes_and_exemplar?'Hide':'Show'}} Notes</v-btn>
				</div>
				<div class="k-case-tree-item-details" v-if="!inline||show_notes_and_exemplar">
					<!-- resource_link -->
					<div v-if="resource_link" class="k-case-tree-item-details-resource-link">
						<v-btn color="primary" class="k-tight-btn k-nocaps-btn" :href="resource_link" target="_blank"><v-icon small class="mr-2">fas fa-bolt</v-icon>Teaching Resources<v-icon small class="ml-2">fas fa-long-arrow-right</v-icon></v-btn>
					</div>
					<!-- notes -->
					<div v-if="marked_notes&&(!inline||show_notes_and_exemplar)" class="k-case-tree-item-details-notes k-case-tree-markdown">
						<div class="k-case-tree-item-details-notes-header">Notes</div>
						<span class="float-right ml-3" style="margin-right:6px;"><CopyBtn :val="item.cfitem.notes" color="#666"/></span>
						<div v-html="marked_notes"></div>
					</div>

					<!-- exemplar -->
					<div v-if="marked_exemplar&&(!inline||show_notes_and_exemplar)" class="k-case-tree-item-details-exemplar k-case-tree-markdown">
						<div class="k-case-tree-item-details-exemplar-header">
							{{ss_framework_data.exemplar_label?ss_framework_data.exemplar_label:'Supplemental Information'}}
							<span v-if="exemplar_collapsible&&!expanded_tile" class="pl-3 pr-1"><a @click="toggle_exemplar">{{exemplar_collapsed_local?'EXPAND':'COLLAPSE'}}</a></span>
							<!-- <span v-if="!exemplar_collapsed_local" class="pl-1 pr-1"><v-icon color="#999" small @click="exemplar_dialog_open=true">fas fa-arrow-up-right-from-square</v-icon></span> -->
						</div>
						<!-- don't include copy btn here because the exemplar is likely to include formatting that the copy btn wouldn't pick up -->
						<!-- <span class="float-right ml-3" style="margin-right:6px;"><CopyBtn :val="marked_exemplar" color="#666"/></span> -->
						<div class="fr-view k-case-tree-item-details-exemplar-body" v-html="marked_exemplar"></div>
					</div>

					<CASEItemAssociations v-if="!inline&&(show_associations||making_associations)" :item="item" :home_framework_record="framework_record" :framework_record="framework_record" :viewer="viewer" :show_full_text="true" :in_expanded_tile="expanded_tile" @show_rubric_from_association="show_rubric_from_association" />
					<div v-if="!inline&&item_is_document" class="k-case-tree-item-details-inner k-case-tree-item-details-inner-document" :class="(marked_notes||marked_exemplar)?'k-case-tree-item-details-inner-no-border':''">
						<ul class="k-list-no-bullets">
							<li v-if="item.cfitem.adoptionStatus"><b>Status:</b> <div class="k-case-tree-item-adoption-status" :class="adoption_status_css_class">{{adoption_status_display_value}}</div></li>
							<li><b>Creator:</b> {{item.cfitem.creator}}</li>
							<li v-if="item.cfitem.publisher" style="hyphens:auto"><b>Publisher:</b> {{item.cfitem.publisher}}</li>
							<li v-if="item.cfitem.language" style="hyphens:auto"><b>Language:</b> {{language_display}}</li>
							<li v-if="item.cfitem.licenseURI"><b>License:</b> {{license_title}}
								<b v-if="license_json.licenseText" style="font-size:14px" class="ml-1"><a @click="license_text_showing=!license_text_showing">{{license_text_showing?'HIDE':'SHOW'}}</a></b>
								<b v-if="!license_json.licenseText" style="font-size:14px" class="ml-1"><a target="_blank" href="item.cfitem.licenseURI.uri">LINK</a></b>
								<div v-if="license_text_showing" v-html="license_json.licenseText.replace(/\n/g, '<br>')" class="mb-2" style="text-indent:0; background-color:#eee; padding:4px; border-radius:6px;"></div>
							</li>
							<li v-if="item.cfitem.frameworkType"><b>Framework Type:</b> {{item.cfitem.frameworkType}}</li>
							<li v-if="item.cfitem.subject"><b>Subject:</b> {{cfdocument_subject}}</li>
							<!-- <li><CopyBtn :size="'small'" :val="item.cfitem.uri" class="mr-1"/><b>CASE URL:</b> <a :href="item.cfitem.uri" target="_blank">{{item.cfitem.uri}}</a></li> -->
							<li v-if="item.cfitem.officialSourceURL" style="hyphens:auto"><CopyBtn :size="'small'" :val="item.cfitem.officialSourceURL" class="mr-1"/><b>Official Source URL:</b> <a :href="item.cfitem.officialSourceURL" target="_blank">{{item.cfitem.officialSourceURL}}</a></li>
							<li v-if="item.cfitem.description" style="hyphens:auto"><CopyBtn :size="'small'" :val="item.cfitem.description" class="mr-1"/><b>Description:</b> <span class="k-marked-inline" v-html="marked(item.cfitem.description)"></span></li>
							<li><CopyBtn :size="'small'" :val="item.cfitem.identifier" class="mr-1" style="margin-top:-2px"/><b>Globally Unique Identifier:</b> {{item.cfitem.identifier}}</li>
							<li v-if="item.cfitem.version"><b>Version:</b> {{item.cfitem.version}}</li>
							<li><b>Last Changed:</b> {{item.cfitem.lastChangeDateTime.substr(0,10)}}</li>
						</ul>
						<div class="text-center mt-2">
							<!-- Note that this menu should be kept in synch with the analogous menu in DocumentEditor -->
							<v-menu :transition="false" left offset-y v-model="edit_actions_menu_showing" z-index="100"><template v-slot:activator="{on}"><v-btn v-on="on" v-show="show_edit_button" small color="primary" class="k-tight-btn"><v-icon small class="mr-1">fas fa-edit</v-icon>Edit Framework (⌘⇧E)</v-btn></template>
								<v-list dense>
									<v-list-item v-if="viewing_home_framework" @click="edit_document('')"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit document properties (⌘⇧E)</v-list-item-title></v-list-item>

									<v-menu v-if="!is_mirror" :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
										<template v-slot:activator="{on}"><v-list-item v-if="viewing_home_framework" v-on="on" style="cursor:pointer">
											<v-list-item-icon><v-icon small>fas fa-plus</v-icon></v-list-item-icon><v-list-item-title>Create/Copy/Import top-level child items</v-list-item-title>
											<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
										</v-list-item></template>

										<v-list dense>
											<v-list-item @click="edit_document('create_top_level_item')"><v-list-item-icon><v-icon small>fas fa-down-long</v-icon></v-list-item-icon><v-list-item-title><b>Create</b> a new top-level item (⌘⇧9)</v-list-item-title></v-list-item>
											<v-list-item @click="edit_document('show_copy_interface')"><v-list-item-icon><v-icon small>far fa-copy</v-icon></v-list-item-icon><v-list-item-title><b>Copy</b> items from this or another framework (⌘⇧C)</v-list-item-title></v-list-item>
											<v-list-item @click="edit_document('show_text_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> items from <b>text input</b> (⌘⇧I)</v-list-item-title></v-list-item>
											<v-list-item @click="edit_document('show_csv_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-csv</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> items from <b>CSV file</b></v-list-item-title></v-list-item>
											<v-list-item @click="edit_document('show_pdf_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-pdf</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> items from <b>PDF</b> (BETA)</v-list-item-title></v-list-item>
											<v-list-item @click="export_item_interface_showing=true"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export items in Satchel text format</v-list-item-title></v-list-item>
										</v-list>
									</v-menu>

									<v-divider/>

									<v-list-item v-if="viewing_home_framework&&!is_mirror" @click="viewer.toggle_move_mode"><v-list-item-icon><v-icon small>fas fa-arrows-alt</v-icon></v-list-item-icon><v-list-item-title>Move items (⌘⇧.)</v-list-item-title></v-list-item>

									<v-list-item v-if="viewing_home_framework&&!is_mirror" @click="viewer.toggle_batch_edit_mode"><v-list-item-icon><v-icon small>fas fa-wand-magic-sparkles</v-icon></v-list-item-icon><v-list-item-title>Batch update items</v-list-item-title></v-list-item>

									<v-list-item @click="toggle_make_associations"><v-list-item-icon><v-icon small>fas fa-arrows-alt-h</v-icon></v-list-item-icon><v-list-item-title>Make associations</v-list-item-title></v-list-item>

									<v-divider/>

									<v-menu :transition="false" :open-on-hover="false" offset-x nudge-top="8" style="display: block;">
										<template v-slot:activator="{on}"><v-list-item v-if="viewing_home_framework" v-on="on" style="cursor:pointer">
											<v-list-item-icon><v-icon small>fas fa-ellipsis-h</v-icon></v-list-item-icon><v-list-item-title>More options</v-list-item-title>
											<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
										</v-list-item></template>

										<v-list dense>
											<v-list-item @click="viewer_fn('delete_framework')"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete framework…</v-list-item-title></v-list-item>

											<v-divider/>

											<v-list-item @click="edit_document('show_association_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch import <b>associations</b> from text input</v-list-item-title></v-list-item>
											<v-list-item v-if="!is_mirror" @click="edit_document('show_metadata_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch import <b>item metadata</b> from text input</v-list-item-title></v-list-item>

											<v-divider/>

											<v-list-item @click="update_rest_api"><v-list-item-icon><v-icon small>fas fa-bed</v-icon></v-list-item-icon><v-list-item-title>Update data for CASE REST API</v-list-item-title></v-list-item>
											<v-list-item v-if="user_can_admin&&!is_mirror&&!is_sandbox" @click="viewer_fn('create_sandbox')"><v-list-item-icon><v-icon small>fas fa-umbrella-beach</v-icon></v-list-item-icon><v-list-item-title>Create a “sandbox” copy of this framework</v-list-item-title></v-list-item>
											<v-list-item v-if="user_can_admin&&!is_mirror&&is_sandbox" @click="viewer_fn('apply_sandbox')"><v-list-item-icon><v-icon small>fas fa-umbrella-beach</v-icon></v-list-item-icon><v-list-item-title>Apply sandbox changes to original framework</v-list-item-title></v-list-item>						
											<v-list-item v-if="user_can_admin&&!is_sandbox" @click="viewer_fn('create_derivative')"><v-list-item-icon><v-icon small>fas fa-arrow-up-from-bracket</v-icon></v-list-item-icon><v-list-item-title>Create a “derivative” copy of this framework</v-list-item-title></v-list-item>
										</v-list>
									</v-menu>

								</v-list>
							</v-menu>

							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-on="on" small color="#666" dark @click="more_info" class="ml-2 k-tight-btn"><v-icon small class="mr-1">fas fa-code</v-icon>Document Metadata</v-btn></template><div class="text-center">Show all document metadata</div></v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-show="viewing_home_framework&&$vuetify.breakpoint.smAndUp&&signed_in" v-on="on" fab x-small color="#666" dark class="ml-1" @click.stop="viewer.side_by_side_editor_head_identifier=item.cfitem.identifier"><v-icon small>fas fa-code-compare</v-icon></v-btn></template><div class="text-center">Side-by-Side Comparison<br>to Archive [BETA]</div></v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-show="viewing_home_framework&&$vuetify.breakpoint.smAndUp" v-on="on" fab x-small color="#666" dark class="ml-1" @click.stop="print_view"><v-icon small>fas fa-print</v-icon></v-btn></template>Print</v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-on="on" fab x-small color="#666" dark class="ml-1" @click="show_qrcode=true"><v-icon small>fas fa-qrcode</v-icon></v-btn></template>QR Code</v-tooltip>
						</div>
					</div>
					<div v-if="!inline&&!item_is_document" class="k-case-tree-item-details-inner k-case-tree-item-details-inner-item" :class="(marked_notes||marked_exemplar)?'k-case-tree-item-details-inner-no-border':''">
						<div v-show="show_edit_button" style="flex:0 1 20px">
							<!-- Note that this menu should be kept in synch with the analogous menu in ItemEditor -->
							<v-menu :transition="false" right offset-y v-model="edit_actions_menu_showing" z-index="100"><template v-slot:activator="{on}"><v-btn v-on="on" fab x-small color="primary" dark><v-icon small>fas fa-edit</v-icon></v-btn></template>
								<v-list dense>
									<v-list-item v-if="!is_mirror&&!is_derived_item&&viewing_home_framework" @click="edit_item('')"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit this item (⌘⇧E)</v-list-item-title></v-list-item>
									<v-list-item v-if="is_derived_item&&viewing_home_framework"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title><i>This derived item cannot be edited.</i></v-list-item-title></v-list-item>

									<v-menu :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
										<template v-slot:activator="{on}"><v-list-item v-if="viewing_home_framework&&!expanded_tile" v-on="on" style="cursor:pointer">
											<v-list-item-icon><v-icon small>fas fa-plus</v-icon></v-list-item-icon><v-list-item-title>Create/Copy/Import</v-list-item-title>
											<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
										</v-list-item></template>

										<v-list dense>
											<v-list-item v-if="!is_mirror" @click="edit_item('add_sibling')"><v-list-item-icon><v-icon small>fas fa-down-long</v-icon></v-list-item-icon><v-list-item-title>Create a <b>new sibling</b> item (⌘⇧0)</v-list-item-title></v-list-item>
											<v-list-item v-if="!is_mirror" @click="edit_item('add_child')"><v-list-item-icon><v-icon small style="transform:rotate(90deg);">fas fa-turn-up</v-icon></v-list-item-icon><v-list-item-title>Create a <b>new child</b> item (⌘⇧9)</v-list-item-title></v-list-item>
											<v-list-item v-if="!is_mirror" @click="edit_item('show_copy_interface')"><v-list-item-icon><v-icon small>fas fa-copy</v-icon></v-list-item-icon><v-list-item-title><b>Copy</b> child items from this or another framework (⌘⇧C)</v-list-item-title></v-list-item>
											<v-list-item v-if="!is_mirror" @click="edit_item('show_text_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> child items from <b>text input</b> (⌘⇧I)</v-list-item-title></v-list-item>
											<v-list-item v-if="!is_mirror" @click="edit_item('show_csv_import_interface')"><v-list-item-icon><v-icon small>fas fa-file-csv</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> child items from <b>CSV file</b></v-list-item-title></v-list-item>
											<v-list-item @click="export_item_interface_showing=true"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export items in Satchel text format</v-list-item-title></v-list-item>
										</v-list>
									</v-menu>

									<v-menu v-if="!is_mirror&&viewing_home_framework&&!expanded_tile&&item_is_node" :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
										<template v-slot:activator="{on}"><v-list-item v-if="viewing_home_framework" v-on="on" style="cursor:pointer">
											<v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete</v-list-item-title>
											<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
										</v-list-item></template>

										<v-list dense>
											<v-list-item @click="edit_item('delete_item')"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item and its child items</v-list-item-title></v-list-item>
											<v-list-item v-if="!expanded_tile" @click="edit_item('delete_children')"><v-list-item-icon><v-icon small>far fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item’s child items but preserve this item</v-list-item-title></v-list-item>
											<v-list-item @click="edit_item('delete_item_not_children')"><v-list-item-icon><v-icon small>far fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item but preserve child items</v-list-item-title></v-list-item>
										</v-list>
									</v-menu>
									<v-list-item v-if="!is_mirror&&viewing_home_framework&&!expanded_tile&&item_is_leaf" @click="edit_item('delete_item')"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item</v-list-item-title></v-list-item>

									<v-list-item v-if="!is_mirror&&viewing_home_framework&&!expanded_tile&&item_is_node" @click="viewer.sort_children(item)"><v-list-item-icon><v-icon small>fas fa-arrow-down-short-wide</v-icon></v-list-item-icon><v-list-item-title>Sort child items</v-list-item-title></v-list-item>
									
									<v-divider/>

									<v-list-item v-if="!is_mirror&&viewing_home_framework&&!expanded_tile" @click="viewer.toggle_move_mode"><v-list-item-icon><v-icon small>fas fa-arrows-alt</v-icon></v-list-item-icon><v-list-item-title>Move items (⌘⇧.)</v-list-item-title></v-list-item>

									<v-list-item v-if="!is_mirror&&viewing_home_framework&&!expanded_tile" @click="viewer.toggle_batch_edit_mode"><v-list-item-icon><v-icon small>fas fa-wand-magic-sparkles</v-icon></v-list-item-icon><v-list-item-title>Batch update items</v-list-item-title></v-list-item>

									<v-list-item v-if="!expanded_tile" @click="toggle_make_associations"><v-list-item-icon><v-icon small>fas fa-arrows-alt-h</v-icon></v-list-item-icon><v-list-item-title>Make associations</v-list-item-title></v-list-item>

									<v-list-item v-if="!item_has_rubric&&!expanded_tile" @click="create_rubric"><v-list-item-icon><v-icon small>fa-ballot-check</v-icon></v-list-item-icon><v-list-item-title>Create rubric (BETA)</v-list-item-title></v-list-item>
								</v-list>
							</v-menu>
						</div>
						<div style="flex:0 1 100%" v-if="item_type">
							<div class="k-case-tree-item-details-label">Item Type:</div>
							<div v-html="item_type"></div>
						</div>
						<div style="flex:0 1 100%" v-if="item.cfitem.educationLevel">
							<div class="k-case-tree-item-details-label">Ed. Level:</div>
							<div v-html="education_level_display"></div>
						</div>
						<div style="flex:0 1 100%">
							<div class="k-case-tree-item-details-label">Last Changed:</div>
							<div v-html="item.cfitem.lastChangeDateTime.substr(0,10)"></div>
						</div>
						<div style="flex:0 1 20px" class="mr-2"><nobr>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-on="on" icon small @click.stop="more_info"><v-icon small>fas fa-code</v-icon></v-btn></template>Show all item metadata</v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-show="viewing_home_framework&&$vuetify.breakpoint.smAndUp&&signed_in" v-on="on" icon small @click.stop="viewer.side_by_side_editor_head_identifier=item.cfitem.identifier"><v-icon small>fas fa-code-compare</v-icon></v-btn></template><div class="text-center">Side-by-Side Comparison<br>to Archive [BETA]</div></v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-show="viewing_home_framework&&$vuetify.breakpoint.smAndUp&&item_is_node" v-on="on" icon small @click.stop="show_table_view"><v-icon small>fas fa-table-list</v-icon></v-btn></template>Table View</v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-show="viewing_home_framework&&$vuetify.breakpoint.smAndUp" v-on="on" icon small @click.stop="print_view"><v-icon small>fas fa-print</v-icon></v-btn></template>Print</v-tooltip>
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-on="on" icon small @click="show_qrcode=true"><v-icon small>fas fa-qrcode</v-icon></v-btn></template>QR Code</v-tooltip>
						</nobr></div>
						<!-- <div style="flex:0 1 20px" class="mr-2">
							<v-tooltip bottom z-index="200"><template v-slot:activator="{on}"><v-btn v-on="on" icon @click.stop="find_resources"><v-icon>fas fa-search</v-icon></v-btn></template>Find Resources</v-tooltip>
						</div> -->
					</div>
					<div v-if="!inline && !item_is_document && show_identifiers_in_tiles" class="text-center mt-2">
						<span class="k-case-tree-item-details-label">Identifier:</span> {{item.cfitem.identifier}}<CopyBtn :size="'small'" color="#666" :val="item.cfitem.identifier" class="ml-1"/>
					</div>

					<div v-if="custom_fields_html" class="text-center mt-2" v-html="custom_fields_html"></div>

					<div v-if="!inline && !pinned_tile && !item_is_document && item_is_node && in_viewer_context && viewing_home_framework" class="text-center mt-3" style="margin-bottom:-8px">
						<v-btn small :color="(limit_search_to_this_item&&search_limit_to_item)?'primary':'#777'" text class="k-tight-btn" @click="limit_search_to_this_item=!limit_search_to_this_item"><v-icon small class="mr-1">{{limit_search_to_this_item?'far fa-check-square':'fas fa-search'}}</v-icon> Search children of this item</v-btn>
					</div>
				</div>
			</div>
		</div>
	</div>

	<MirrorInfo v-if="mirror_info_showing" :lsdoc_identifier="framework_record.lsdoc_identifier" @mirror_info_cancel="mirror_info_showing=false" />
	<SatchelExport v-if="export_item_interface_showing" :item="item" :framework_record="framework_record" @dialog_cancel="export_item_interface_showing=false" />
	<v-dialog v-model="show_qrcode" max-width="600" persistentx scrollable :fullscreen="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm"><v-card>
		<v-card-text>
			<div class="text-center mt-4" style="font-size:18px"><b>Scan this QR Code</b> to open this {{item_is_document?'framework':(item_type?item_type:'item')}} on your mobile device:</div>
			<div class="mt-4 d-flex justify-center"><qrcode-vue :value="item_link()" size="320" level="H" /></div>
		</v-card-text>
		<v-card-actions class="pa-2" style="border-top:1px solid #999">
			<v-spacer></v-spacer>
			<v-btn color="primary" small @click="show_qrcode=false"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
		</v-card-actions>
	</v-card></v-dialog>

</div>
<CFRubricModal v-if="rubric_identifier_showing" :initiating_cfitem="item.cfitem" :rubric_identifier="rubric_identifier_showing" :initial_rubric_component_identifier_showing="initial_rubric_component_identifier_showing" :framework_record="framework_record" @dialog_cancel="rubric_identifier_showing=null;initial_rubric_component_identifier_showing=null" />
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASEItemAssociations from './CASEItemAssociations'
import CopyBtn from '../utilities/CopyBtn'
import TileComments from '../comments/TileComments'
import SatchelExport from '../edit/SatchelExport'
import MirrorInfo from './MirrorInfo'
import QrcodeVue from 'qrcode.vue'
import CFRubricModal from '@/components/rubric/CFRubricModal.vue'


export default {
	name: 'CASEItemTile',
	components: { CopyBtn, CASEItemAssociations, TileComments, SatchelExport, MirrorInfo, QrcodeVue, CFRubricModal},
	props: {
		item: { type: Object, required: true },
		home_framework_record: { type: Object, required: true },
		// this should be sent in if we're viewing an item that isn't part of the home framework record (i.e. it's from an associated framework)
		item_framework_record: { required: false, default() { return null }},
		// note that the tile is currently only shown in the "viewer context"
		viewer: { required: true },
		pinned_tile: { type: Boolean, required: false, default() { return false} },
		open_nodes_override: { required: false, default() { return null }},
		// if this is true, the tile is showing in the "expanded view", above the rest of the interface with its own history
		expanded_tile: { type: Boolean, required: false, default() { return false} },
	},
	data() { return {
		edit_actions_menu_showing: false,
		exemplar_collapsed_local: false,
		show_notes_and_exemplar: false,
		export_item_interface_showing: false,
		license_text_showing: false,
		mirror_info_showing: false,
		show_qrcode: false,
		resource_link: '',
		rubric_identifier_showing: null,
		initial_rubric_component_identifier_showing: null,
		view: 'form',
	}},
	computed: {
		...mapState(['framework_records', 'association_type_labels', 'case_version_displayed', 'embedded_mode', 'embedded_mode_chooser', 'languages']),
		...mapGetters(['signed_in', 'show_identifiers_in_tiles']),
		show_associations() {
			return this.viewer.show_associations
			// we used to do this, but we don't want to show associations even in the expanded_tile if the user has chosen to hide associations, right?
			// return this.viewer.show_associations || this.expanded_tile
		},

		// tiles are always shown in the viewer context (at least for now)
		in_viewer_context() { return true },

		show_tile_comments_component() { 
			// if any of these are true, don't show comments
			if (this.embedded_mode) return false
			if (this.pinned_tile) return false
			if (!this.viewing_home_framework) return false

			// if user has clicked to show comments (lst.show_comments is true), show if the user is siend in or public_review is on
			if (this.$store.state.lst.show_comments) {
				if (this.signed_in) return true
				if (this.viewer.public_review_on) return true
			}

			return false
		},

		framework_record() {
			// if we received an item_framework_record, use it; otherwise use the home_framework_record
			if (this.item_framework_record) return this.item_framework_record
			return this.home_framework_record
		},
		ss_framework_data() { return this.framework_record.ss_framework_data },
		viewing_home_framework() { return this.framework_record == this.home_framework_record },

		basic_search_tree_key_hash() { return this.viewer.basic_search_tree_key_hash ? this.viewer.basic_search_tree_key_hash : {} },
		batch_edit_mode() { return this.viewer.batch_edit_mode },
		edited_node() { return this.viewer.edited_node },
		stats_mode() { return this.viewer.stats_mode },
		maximized() { return this.viewer.maximized },

		// the tile will be shown inline if the framework viewer isn't maximized, or if we're on a small screen
		inline() {
			if (this.expanded_tile) return false
			return !this.maximized || this.$vuetify.breakpoint.xs
		},

		is_sandbox() { return !empty(this.framework_record.ss_framework_data.sandboxOfIdentifier) },

		is_mirror() { return this.framework_record.ss_framework_data.is_mirror === 'yes' },

		is_derivative() { return this.viewer.is_derivative },
		derivative_original_framework_record() { return this.viewer.derivative_original_json },
		derivative_original_json() { return this.viewer.derivative_original_json },
		is_derived_item() {
			if (!this.is_derivative || !this.derivative_original_json) return false
			return this.derivative_original_json.CFItems.findIndex(x=>x.identifier == this.item.cfitem.identifier) > -1
		},

		editing_enabled() { return this.viewer.editing_enabled },
		user_can_edit() { return this.viewer.user_can_edit },
		making_associations() { return this.viewer.current_editor && this.viewer.current_editor.make_association },	// this is pretty hackish

		show_chooser_btn() {
			if (this.embedded_mode && this.item_is_document) return false
			if (this.inline && this.embedded_mode_chooser && !this.item_is_document) return true
		},

		show_edit_button() {
			// if this item's framework isn't the home framework, you can't edit the item
			if (this.item_framework_record && this.item_framework_record != this.home_framework_record) return false

			// if we're making associations, you can't edit
			if (this.making_associations) return false

			// show the edit button if editing is enabled, the user can edit, and we aren't currently waiting for import confirmation
			return this.editing_enabled && this.user_can_edit && this.viewer.new_imported_item_identifiers.length == 0
		},

		item_is_document() { return empty(this.item.parent_node) },
		adoption_status_css_class() { if (this.item_is_document) return CFDocument.adoption_status_css_class(this.item.cfitem) },
		active_node() { return this.framework_record.active_node },
		open_nodes() {
			if (this.open_nodes_override) return this.open_nodes_override
			else return this.framework_record.open_nodes
		},
		pinned_items() { return this.framework_record.pinned_items },
		children_revealed() { return this.framework_record.children_revealed },
		last_clicked_node() { return this.framework_record.last_clicked_node },
		node_is_open() {
			return this.open_nodes[this.item.tree_key]
		},
		node_btn_style() {
			if (this.node_is_open) return 'transform:rotate(90deg)'
		},
		node_is_active() {
			return (this.active_node == this.item.tree_key)
		},
		item_is_pinned() {
			// TODO: account for the fact that an item can be in multiple places in the tree, and this node's item may already be pinned via another node
			return (this.pinned_items.findIndex(x=>x==this.item.tree_key) > -1)
		},
		item_is_leaf() {
			return this.item.children.length == 0
		},
		item_is_node() {
			return !this.item_is_leaf
		},
		item_is_last_clicked() {
			return this.last_clicked_node == this.item.tree_key
		},

		tracking_changes() {
			return vapp.case_tree_component && vapp.case_tree_component.tracking_changes
		},
		tracked_change_entities() {
			if (!this.tracking_changes) return {}
			return U.get_archive_comparison_entities(this.item.cfitem.identifier, vapp.case_tree_component.framework_record, vapp.case_tree_component.track_changes_framework_record)
		},
		show_tracked_changes() {
			// if we're tracking changes and there's an item on both sides, highlight the difference between current and archive; otherwise we just show what's in this.item
			return this.tracking_changes && this.tracked_change_entities.current.identifier && this.tracked_change_entities.archived.identifier
		},
		tracked_change_icon() {
			if (!this.tracking_changes) return ''
			return U.tracked_change_icon(this.tracked_change_entities)
		},
		track_changes_fields() { return this.$store.state.lst.track_changes_fields },

		language_display() {
			if (empty(this.item.cfitem.language)) return ''
			let o = this.languages.find(x=>x.value == this.item.cfitem.language)
			return o ? o.text : this.item.cfitem.language
		},
		item_type() {
			// allow for the fact that an item's type can be in either CFItemType or CFItemTypeURI.title
			return U.item_type_string(this.item.cfitem)
		},
		cfdocument_subject() {
			if (!this.item_is_document) return ''

			// this is supposed to always come in as an array, but sometimes it's a string
			if (empty(this.item.cfitem.subject)) return ''
			if ($.isArray(this.item.cfitem.subject)) return this.item.cfitem.subject.join(', ')
			return this.item.cfitem.subject
		},
		license_json() {
			return U.get_license_json(this.framework_record)
			if (this.item.cfitem.licenseURI) {
				// look for the license in the framework's CFDefinitions.CFLicenses area
				let licenses = oprop(this.framework_record.json, 'CFDefinitions', 'CFLicenses')
				if (licenses && licenses[0]) {
					return licenses[0]
				}
			}
			return {}
			// note that this.license_json.licenseText will resolve to either null/undefined or the licenseText value
		},
		license_title() {
			if (this.item.cfitem.licenseURI) {
				// try to get the title from the license_json (even though it should really be identical with the title in licenseURI)
				if (this.license_json.title) return this.license_json.title
				else return this.item.cfitem.licenseURI.title
			}
		},
		human_coding_scheme() {
			if (this.show_tracked_changes && this.track_changes_fields.humanCodingScheme && this.track_changes_fields.highlight_text_diffs) {
				let s = this.tracked_change_entities.current.humanCodingScheme ?? ''
				let archived = this.tracked_change_entities.archived.humanCodingScheme ?? ''
				let diff = U.diff_string(archived, s)
				return (this.in_viewer_context) ? diff.n : diff.o

			} else {
				return this.highlight_search_terms(this.item.cfitem.humanCodingScheme, 'humanCodingScheme')
			}
		},
		marked_full_statement() {
			// if we need mathjax for latex translation and it's not ready, return; the computed will re-run once mathjax is ready
			if (this.$store.state.mathjax_initialized == 'started') return ''

			if (this.show_tracked_changes && this.track_changes_fields.fullStatement && this.track_changes_fields.highlight_text_diffs) {
				let s, archived

				if (this.item_is_document) {
					s = this.tracked_change_entities.current.title
					archived = this.tracked_change_entities.archived.title
				} else {
					// preserve latex here; then do diff_string, then render latex below
					s = marked(U.preserve_latex(this.tracked_change_entities.current.fullStatement))
					archived = marked(U.preserve_latex(this.tracked_change_entities.archived.fullStatement))
				}

				let diff = U.diff_string(archived, s, 'preserve_tags')
				// show the new value if we're on the left side, or the old value on the right
				diff = (this.in_viewer_context) ? diff.n : diff.o

				return U.render_latex(U.preserve_latex_reverse(diff))

			} else {
				// for the document, return the doc title
				if (this.item_is_document) return this.item.cfitem.title
				// return U.render_latex(this.item.cfitem.fullStatement)

				let s = U.marked_latex(this.item.cfitem.fullStatement)
				return this.highlight_search_terms(s, 'fullStatement')
			}
		},
		marked_notes() {
			// if we need mathjax for latex translation and it's not ready, return; the computed will re-run once mathjax is ready
			if (this.$store.state.mathjax_initialized == 'started') return ''

			if (!this.item.cfitem.notes) return ''
			let s

			// TEMP HACK
			let cfitem_notes = this.item.cfitem.notes
			if (cfitem_notes.search(/\[xresourcelink\]\((.*?)\)(.*)/) > -1) {
				cfitem_notes = RegExp.$2
				this.resource_link = RegExp.$1
			}

			if (this.show_tracked_changes && this.track_changes_fields.notes && this.track_changes_fields.highlight_text_diffs) {
				// preserve latex here; then do diff_string, then render latex below
				s = marked(U.preserve_latex(this.tracked_change_entities.current.notes))
				let archived = marked(U.preserve_latex(this.tracked_change_entities.archived.notes))

				let diff = U.diff_string(archived, s, 'preserve_tags')
				// show the new value if we're on the left side, or the old value on the right
				s = (this.in_viewer_context) ? diff.n : diff.o
				s = U.render_latex(U.preserve_latex_reverse(s))

			} else {
				s = U.marked_latex(cfitem_notes)
				s = this.highlight_search_terms(s, 'notes')
			}

			// make sure all links open in a new window (though notes really shouldn't have links; note that for exemplars froala should ensure _blank is included)
			s = s.replace(/<a /g, '<a target="_blank" ')

			return s	
		},
		marked_exemplar() {
			// if we need mathjax for latex translation and it's not ready, return; the computed will re-run once mathjax is ready
			if (this.$store.state.mathjax_initialized == 'started') return ''

			// if we're not showing vext CASE, don't show exemplars
			if (this.case_version_displayed != 'vext') return ''
			if (!this.item.cfitem.extensions.supplementalNotes) return ''
			let exs
			if (this.show_tracked_changes && this.track_changes_fields.supplementalNotes && this.track_changes_fields.highlight_text_diffs) {
				// note: tracked_change_entities.current and tracked_change_entities.archived will have supplementalNotes as direct fields, not part of extensions
				// preserve latex here; then do diff_string, then render latex below
				exs = U.preserve_latex(this.tracked_change_entities.current.supplementalNotes)
				if (exs.search(/<\w+[^<]*>/) == -1) exs = marked(exs)

				let archived = U.preserve_latex(this.tracked_change_entities.archived.supplementalNotes)
				if (archived.search(/<\w+[^<]*>/) == -1) archived = marked(archived)

				let diff = U.diff_string(archived, exs, 'preserve_tags')
				// show the new value if we're on the left side, or the old value on the right
				exs = (this.in_viewer_context) ? diff.n : diff.o
				exs = U.render_latex(U.preserve_latex_reverse(exs))

			} else {
				// note that examplars can either use html or markdown; use marked if we don't have any html tags
				// also note that if the author uses latex, they shouldn't also try to use markdown, because the latex renderer will render the latex as html, so marked() won't be run here
				exs = this.item.cfitem.extensions.supplementalNotes
				if (exs.search(/<\w+[^<]*>/) == -1) exs = U.marked_latex(exs)
				else exs = U.render_latex(exs)
				exs = this.highlight_search_terms(exs, 'exemplar')
			}

			// if exemplar includes <hr>s, convert the line after each <hr> to an expandable div
			let arr = exs.split('<hr>')
			if (arr.length == 1) {
				// no blocks; just return exs
				return exs

			} else {
				// start with whatever was in arr[0] (which might be an empty string)
				let html = arr[0].replace(/(<br>)+$/, '')

				// for each additional block
				for (let i = 1; i < arr.length; ++i) {
					// try to extract the first line, which is a little tricky because it might be a paragraph, or it might be a blank line separated by a <br> or a <ol> or <ul>
					let header, footer
					if (arr[i].search(/^\s*<p>(.*?)<\/p>([\s\S]*)/) > -1) {
						header = RegExp.$1
						footer = RegExp.$2
					} else if (arr[i].search(/^(.*?)((<br|<ol|<ul)[\s\S]*)/) > -1) {
						header = RegExp.$1
						footer = RegExp.$2
					}

					if (header && footer) {
						// remove leading <br> from footer if there (that br would be the end of the header line)
						footer = footer.replace(/^<br>/, '')

						let block_id = U.new_uuid()
						html += sr('<div class="k-exemplar-block-header" data-header-id="$1" data-block-showing="yes"><i class="fas fa-caret-right"></i> $2</div><div class="k-exemplar-block-body" data-block-id="$1">$3</div>', block_id, header, footer)

						this.$nextTick(x=>{
							$('[data-header-id=' + block_id + ']').click(function() {
								let jq = $(this)
								let block_id = jq.attr('data-header-id')
								let showing = jq.attr('data-block-showing')
								if (showing == 'no') {
									$('[data-block-id=' + block_id + ']').slideDown()
									jq.find('.fas').removeClass('fa-caret-right').addClass('fa-caret-down')
									jq.attr('data-block-showing', 'yes')
								} else {
									$('[data-block-id=' + block_id + ']').slideUp()
									jq.find('.fas').removeClass('fa-caret-down').addClass('fa-caret-right')
									jq.attr('data-block-showing', 'no')
								}
							})
						})

					} else {
						html += sr('<div class="k-exemplar-block-body-no-header">$1</div>', arr[i])
					}
				}

				return html
			}
		},
		exemplar_collapsible() { return this.marked_exemplar.indexOf('k-exemplar-block-header') > -1 },
		education_level_display() {
			let low = this.item.cfitem.educationLevel[0]
			let high = this.item.cfitem.educationLevel[this.item.cfitem.educationLevel.length-1]
			if (low == high) return low
			else return sr('$1–$2', low, high)
			// this overlooks the possibility of education levels that aren't a range, but that's very unlikely
		},
		adoption_status_display_value() { return CFDocument.adoption_status_display_value(this.item.cfitem.adoptionStatus) },

		// this gets toggled between true and false when the user clicks the btn to limit searches to this item
		limit_search_to_this_item: {
			// see CASEFVSearchMixin for other places where we use lst to track search preferences for a framework
			get() {
				// get the current value for search_item_for_limit
				let search_item_for_limit = 0
				let s = this.$store.state.lst.search_item_for_limit
				if (s) {
					let o = JSON.parse(s)
					if (o[this.framework_record.lsdoc_identifier]) search_item_for_limit = o[this.framework_record.lsdoc_identifier]
				}

				// return whether or not search_item_for_limit matches this item's node
				return (search_item_for_limit == this.item.tree_key)
			},
			set(val) {
				// when the button is clicked, we toggle the value of limit_search_to_this_item so that this fn will be called.
				// however, clicking the btn always indicates that you *want* to limit searches by this item, so...
				// set lst.search_item_for_limit for this framework to this item's tree_key, and set lst.search_limit_to_item to true
				this.viewer.search_item_for_limit = this.item.tree_key
				this.viewer.search_limit_to_item = true
				// note that the computed setter for search_limit_to_item will reveal the search panel

				// if we're expanded, de-expand
				if (this.expanded_tile) this.$emit('full_screen_compressed')
			},
		},
		search_limit_to_item() {
			let s = this.$store.state.lst.search_limit_to_item
			if (s) {
				let o = JSON.parse(s)
				return (o[this.framework_record.lsdoc_identifier]) ? true : false
			} else {
				return false
			}
		},
		item_is_from_home_framework() {
			// if the item is in cfo.cfitems, it's from the home framework
			if (!empty(this.home_framework_record.cfo.cfitems[this.item.cfitem.identifier])) return true
			// else if it's a document and it's the home framework document, it's from the home framework
			if (this.item_is_document && this.item.cfitem.identifier == this.home_framework_record.lsdoc_identifier) return true
			// else it isn't from the home framework
			return false
		},
		item_framework_class() {
			if (!this.item_framework_record) return ''
			const framework_color = U.framework_color(this.item_framework_record.lsdoc_identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-dark'
			return ''
		},
		item_framework_style() {
			return U.framework_color_object(this.item_framework_record.lsdoc_identifier, 'dark')

		},
		item_framework_description() {
			if (!this.item_framework_record) return '<i>This item is from another framework.</i>'
			return sr('From framework: <b>$1</b>', this.item_framework_record.json.CFDocument.title)
		},
		user_can_admin() {
			return vapp.is_granted('admin_framework', this.lsdoc_identifier)
		},

		// for embedded mode
		selected_items() { return this.viewer.selected_items },
		item_is_selected() { 
			if (!this.selected_items) return false
			// select on the basis of selected_nodes, which is calculated in this.viewer
			return this.viewer.selected_nodes.includes(this.item)
		},
		selected_item_icon_style() {
			if (this.item_is_document || !this.item_is_selected) return 'display:none'
			let s = 'font-size:11px!important; margin-left:0px; margin-top:4px; margin-right:2px;'
			// let s = 'font-size:16px!important; margin-left:2px; margin-top:1px; margin-right:2px;'
			// if (this.item_is_document) return s + 'visibility:hidden;'
			// if (!this.item_is_selected) return s + 'visibility:hidden;'
			return s + 'opacity:0.7;'
		},
		custom_fields() {
			if (this.item_is_document) return window.CASE_Custom_Extension_Fields.CFDocument ?? null
			else return window.CASE_Custom_Extension_Fields.CFItem ?? null
		},
		item_has_custom_fields() {
			if (!this.custom_fields) return false
			for (let key in this.custom_fields) {
				if ($.isArray(this.item.cfitem.extensions[key])) {
					if (this.item.cfitem.extensions[key].length > 0) return true
				} else if (!empty(this.item.cfitem.extensions[key])) return true
			}
			return false
		},
		custom_fields_html() {
			// no custom fields to show if we're inline, or if there are no CFItem custom fields
			if (!this.custom_fields || this.inline) return ''

			// go through each custom field...
			let s = ''
			for (let key in this.custom_fields) {
				let field = this.custom_fields[key]
				let val = this.item.cfitem.extensions[key]
				// if this item's type doesn't match the field's item types, don't show it
				if (field.applicableItemTypes && field.applicableItemTypes.length > 0 && !field.applicableItemTypes.includes(this.item_type)) continue
				
				// if show_identifiers_in_tiles is false and field.always_display is 'no', don't show it
				if (!this.show_identifiers_in_tiles && field.always_display == 'no') continue

				// if the value is empty don't show it; otherwise format and add to s
				if ($.isArray(val)) {
					if (val.length == 0) continue
					s += `<div class="d-inline py-1 px-2"><span class="k-case-tree-item-details-label">${field.display_key?field.display_key:key}:</span> <span>${val.join(', ')}</span></div>`
				} else {
					if (empty(val)) continue
					s += `<div class="d-inline py-1 px-2"><span class="k-case-tree-item-details-label">${field.display_key?field.display_key:key}:</span> <span>${val}</span></div>`
				}
			}
			return s
		},
		item_has_rubric() {
			// we count this cfitem as having a rubric if it has any association to a rubric or a rubric sub-element;
			// as of now, at least, we don't support multiple rubric associations for the same item
			let arr = this.framework_record.cfo.associations_hash[this.item.cfitem.identifier]
			if (!arr) return false
			return (arr.findIndex(x=>U.is_rubric_association(x)) > -1) 
		},
		progression_table_available() { return U.progression_table_available(this.framework_record, this.item) },
	},
	watch: {
		item: {
			immediate: true, handler() {
				// when the item changes (including when we initially load), expand/collapse the exemplar accordingly

				// TEMP
				this.resource_link = ''

				// if this is a pinned tile, always collapse the exemplar to start
				if (this.pinned_tile) {
					this.$nextTick(x => this.toggle_exemplar(true))
				} else {
					// else always start with the lst value of exemplar_collapsed
					this.$nextTick(x => this.toggle_exemplar(this.$store.state.lst.exemplar_collapsed))
				}
			}
		},
	},
	created() {
		
	},
	mounted() {
		vapp.case_item_tile = this
	},
	methods: {
		item_outer_css() {
			let s = ''

			if (this.expanded_tile) {
				s += ' k-case-tree-item-tile-outer-full-screen'
			} else {
				if (this.item_is_last_clicked) {
					s += ' k-case-tree-item-tile-last-clicked'
				}

				if (this.pinned_items.length > 0 && !this.pinned_tile) {
					s += ' k-case-tree-item-tile-active-with-pinned-items'
				}

				if (this.inline) {
					s += ' k-case-tree-item-tile-outer-inline'
				}

				// if the item has supplementalNotes, allow for more horizontal space
				if (!empty(this.marked_exemplar)) {
					s += ' k-case-tree-item-tile-outer-has-supp-notes'
				}
			}

			// apply color coding by item type, if user hasn't turned it off
			if (this.inline && this.$store.state.lst.show_color_coded_item_types) {
				s += ' k-case-tree-item-type-color'
				let item_type_level = this.framework_record.cfo.item_type_levels.find(x=>x.item_type == U.item_type_string(this.item.cfitem))
				if (item_type_level) {
					s += (' k-case-tree-item-type-color-' + item_type_level.level_index)
				}
			}

			if (!this.ss_framework_data.ignore_status_dates) {
				// add style for items that haven't yet reached statusStartDate, and/or have passed statusEndDate (both shouldn't be true, but that technically could happen)
				// note that here we add the style on outer; in CASEItem we add the style in inner
				// ignore dates < 2010; such dates almost certainly reflect incorrect usage of the fields
				if (!empty(this.item.cfitem.statusStartDate) && this.item.cfitem.statusStartDate > '2010') {
					if (this.$store.state.today < this.item.cfitem.statusStartDate) s += ' k-case-tree-item-not-yet-adopted'
				}
				if (!empty(this.item.cfitem.statusEndDate) && this.item.cfitem.statusEndDate > '2010') {
					if (this.$store.state.today >= this.item.cfitem.statusEndDate) s += ' k-case-tree-item-no-longer-adopted'
					else if (this.item.cfitem.statusEndDate) s += ' k-case-tree-item-scheduled-no-longer-adopted'
				}
			}

			return s
		},

		item_css(hover) {
			// most of these don't do anything as of now (exception: k-case-tree-item-tile-selected)
			let s = ''
			if (!empty(this.edited_node) && this.edited_node.tree_key == this.item.tree_key) {
				s += ' k-case-tree-item-tile-being-edited'
			}

			if (this.basic_search_tree_key_hash[this.item.tree_key]) {
				s += ' k-case-tree-item-tile-search-match'
			}

			if (this.item.cfitem.stats.duplicate_hovered) {
				s += ' k-case-tree-item-tile-duplicate-hovered'
			}

			if (this.item_is_document) {
				s += ' k-case-tree-item-document'
			}

			if (this.selected_items && this.item_is_selected) {
				s += ' k-case-tree-item-tile-selected'
			}

			if (this.item_is_leaf) {
				s += ' k-case-tree-item-tile-leaf'
			} else {
				s += ' k-case-tree-item-tile-node'
			}

			return s
		},

		chooser_style(hover) {
			return 'font-size:18px'
			if (this.item.cat) return 'font-size:16px'	// current association type
			else return 'font-size:20px'
		},
		chooser_icon(hover) {
			// marker for already-aligned item in resource alignment mode
			if (this.item.cat) return 'fas fa-check'

			// return check-circle if we're in the viewer context and this is the framework's chosen_node
			if (this.in_viewer_context && this.framework_record.chosen_node == this.item.tree_key) return 'fas fa-square-check'
			// or node_is_chosen_local is set to true
			if (this.node_is_chosen_local) return 'fas fa-square-check'

			// if we have chosen_items and this item's identifier is among them...
			// if we have chosen_items or selected_items and this item's identifier is among them...
			if (this.chosen_items?.find(x=>x==this.item.cfitem.identifier) || this.selected_items?.find(x=>x==this.item.cfitem.identifier)) {
				// if hovering, return an 'x', because clicking it will remove the association; else return check
				if (hover) return 'fas fa-square-xmark'
				else return 'fas fa-square-check'
			}

			// if we get to here show blank square
			return 'far fa-square'
		},
		chooser_color(hover) {
			// green if we're in the viewer context and this is the framework's chosen_node
			if (this.in_viewer_context && this.framework_record.chosen_node == this.item.tree_key) return 'green'
			// green if node_is_chosen_local is set to true
			if (this.node_is_chosen_local) return 'green'
			// green if item.cat is true
			if (this.item.cat) return 'green'

			// if we have selected_items or chosen_items and this item's identifier is among them...
			if (this.chosen_items?.find(x=>x==this.item.cfitem.identifier) || this.selected_items?.find(x=>x==this.item.cfitem.identifier)) {
				// if hovering, return red, because clicking it will remove the association; otherwise return orange
				if (hover) return 'red'
				else return 'orange'
			}

			// if we get to here, green if hovering or gray otherwise
			if (hover) return 'green'
			else return '#ccc'
		},

		marked(s) {
			return marked(s)
		},

		highlight_search_terms(s, field) {
			// if we have a highlight_search_terms fn in viewer
			if (this.viewer.highlight_search_terms) {
				// and if field is included in viewer.search_field_types
				if (this.viewer.search_field_types.includes(field)) {
					// then highlight
					s = this.viewer.highlight_search_terms(s, this.item.tree_key)
				}
			}

			return s
		},

		tree_statement_for_copy() {
			// for the document, return the doc title
			if (empty(this.item.parent_node)) return this.item.cfitem.title

			let s = this.item.cfitem.fullStatement
			if (this.item.cfitem.humanCodingScheme) s = this.item.cfitem.humanCodingScheme + ' ' + s
			return s
		},

		mouseover() { this.$store.commit('set', [this.framework_record, 'hovered_tile', this.item.tree_key])},
		mouseout() { this.$store.commit('set', [this.framework_record, 'hovered_tile', ''])},

		close_tile() {
			// clear hovered_tile
			this.$nextTick(x=>this.$store.commit('set', [this.framework_record, 'hovered_tile', '']))

			// if pinned_tile, unpin the item
			if (this.pinned_tile) {
				this.toggle_pin()
				return
			}

			// clear the active node and starting_lsitem_identifier
			this.viewer.make_node_active('', true)

			// set last_clicked_node, to help the user remember what they last clicked
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.item.tree_key])
		},

		show_chooser_fn_clicked(evt) {
			if (this.in_viewer_context) this.viewer.show_chooser_fn(this, this.item, evt)
		},

		tracked_change_clicked() {
			setTimeout(x=>this.viewer.show_archived_item(this.item), 100)
		},

		item_clicked() {
			if (!this.viewer.lsdoc_identifier) {
				return
			}

			if (this.pinned_tile) {
				this.viewer.make_node_parents_open(this.item.tree_key)
				this.viewer.make_node_open(this.item.tree_key)
				this.viewer.make_node_active(this.item.tree_key, true)
				this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.item.tree_key])

			// when showing the tile inline, close it on click
			} else if (this.inline) {
				this.close_tile()
			}
		},

		toggle_pin() {
			if (this.item_is_pinned) {
				this.$store.commit('splice_from_array', [this.framework_record.pinned_items, this.item.tree_key])
			} else {
				this.$store.commit('add_to_array', [this.framework_record.pinned_items, this.item.tree_key])
			}

			// store pinned items in localstorage
			// (note that code in CASETree also deals with this lst property)
			let s = this.$store.state.lst.pinned_items
			let o = (s) ? JSON.parse(s) : {}
			o[this.framework_record.lsdoc_identifier] = this.framework_record.pinned_items
			this.$store.commit('lst_set', ['pinned_items', JSON.stringify(o)])
		},

		edit_item(action) {
			this.edit_actions_menu_showing = false
			this.viewer.edit_item(this.item, action)

			// if we're showing in the expanded view, hide the expanded view so the editor can be viewed
			if (this.expanded_tile) this.$emit('full_screen_compressed')
		},
		edit_document(action) {
			this.edit_actions_menu_showing = false
			this.viewer.edit_document(action)
		},
		more_info() {
			if (this.expanded_tile) this.$emit('more_info')
			else this.viewer.more_info(this.item)
		},
		print_view() {
			this.viewer.print_view(this.item)
		},
		show_table_view() {
			this.viewer.table_mode_start_node = this.item
			this.viewer.viewer_table_mode = 'items'
			this.viewer.viewer_mode = 'table'
		},

		show_qr_code() {

		},

		viewer_fn(fn_name, evt) {
			this.edit_actions_menu_showing = false
			this.viewer[fn_name](evt)
		},

		toggle_make_associations() {
			let node
			if (!this.item_is_document) node = this.item
			this.viewer.toggle_make_associations(node)
		},

		find_resources() {
			this.$alert('Find resources associated with this standard…')
		},

		toggle_exemplar(new_collapsed_value) {
			// if expanded_tile is true, we always have the exemplar expanded
			if (this.expanded_tile) return

			// set or toggle exemplar_collapsed_local, then set to_show to 'yes' or 'no' depending on current value
			if (new_collapsed_value === false || new_collapsed_value === true) this.exemplar_collapsed_local = new_collapsed_value
			else this.exemplar_collapsed_local = !this.exemplar_collapsed_local

			// if we're not showing in a pinned tile, store value of exemplar_collapsed to lst
			if (!this.pinned_tile) this.$store.commit('lst_set', ['exemplar_collapsed', this.exemplar_collapsed_local])

			let to_show = (this.exemplar_collapsed_local) ? 'no' : 'yes'

			$(this.$el).find('[data-header-id]').each(function() {
				let jq = $(this)
				let block_id = jq.attr('data-header-id')
				if (to_show == 'yes') {
					$('[data-block-id=' + block_id + ']').slideDown()
					jq.find('.fas').removeClass('fa-caret-right').addClass('fa-caret-down')
					jq.attr('data-block-showing', 'yes')
				} else {
					$('[data-block-id=' + block_id + ']').slideUp()
					jq.find('.fas').removeClass('fa-caret-down').addClass('fa-caret-right')
					jq.attr('data-block-showing', 'no')
				}
			})
		},

		expand_tile() {
			this.viewer.show_expanded_item(this.item, this.framework_record)
		},

		update_rest_api() {
			U.loading_start()
			U.ajax('sync_case_api', {framework_identifiers: [this.framework_record.lsdoc_identifier]}, result=>{
				U.loading_stop()
				this.$alert('CASE REST API data updated. (Note that if you don’t do this manually, an automated service runs every 15 minutes to update CASE REST API data for any frameworks that have been changed.)')
			})
		},

		// for qrcode
		item_link() {
			return window.location.toString()
		},

		show_progression_table() { vapp.case_tree_component.show_progression_table(this.framework_record, this.item) },

		show_rubric_from_association(assoc) {
			// note: we assume here that the rubric is located in the framework we're viewing
			let rubric = U.get_rubric_from_association(assoc, this.framework_record)
			if (!rubric) {
				this.$alert('Couldn’t find rubric...')
				return
			}
			this.rubric_identifier_showing = rubric.identifier
			// set initial_rubric_component_identifier_showing to the rubric component (a rubric, criterion, or level) that was clicked on
			this.initial_rubric_component_identifier_showing = assoc.destinationNodeURI.identifier
		},

		create_rubric() {
			// to create a new rubric, we just generate a new uuid for the rubric to be created; the rubric modal will show the editor and allow it to be created
			this.rubric_identifier_showing = U.new_uuid()
			this.initial_rubric_component_identifier_showing = null
		},

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// "Statistics" mode

		stats_mouseover() {
			let i = this.item.cfitem

			let dup
			if (i.stats.is_duplicate_of) dup = i.stats.is_duplicate_of
			else if (i.stats.add_is_child_of_to_duplicate) dup = i.stats.add_is_child_of_to_duplicate
			else if (i.stats.possible_related_to) dup = i.stats.possible_related_to
			else if (i.stats.to_be_deleted) dup = i.stats.to_be_deleted

			if (dup) {
				this.framework_record.cfo.cfitems[dup].stats.duplicate_hovered = true
			}
		},

		stats_mouseout() {
			let i = this.item.cfitem

			let dup
			if (i.stats.is_duplicate_of) dup = i.stats.is_duplicate_of
			else if (i.stats.add_is_child_of_to_duplicate) dup = i.stats.add_is_child_of_to_duplicate
			else if (i.stats.possible_related_to) dup = i.stats.possible_related_to
			else if (i.stats.to_be_deleted) dup = i.stats.to_be_deleted

			if (dup) {
				this.framework_record.cfo.cfitems[dup].stats.duplicate_hovered = false
			}
		},

		stats_clicked() {
			let i = this.item.cfitem

			let dup
			if (i.stats.is_duplicate_of) dup = i.stats.is_duplicate_of
			else if (i.stats.add_is_child_of_to_duplicate) dup = i.stats.add_is_child_of_to_duplicate
			else if (i.stats.possible_related_to) dup = i.stats.possible_related_to
			else if (i.stats.to_be_deleted) dup = i.stats.to_be_deleted

			if (dup) {
				console.log('duplicate_of: ' + dup)
				let search_terms = dup
				if (dup != i.identifier) search_terms += ' OR ' + i.identifier
				this.$store.commit('set', [this.framework_record, 'search_terms', search_terms])
				this.viewer.execute_search_start(false)
			}
		},

		// rough demo showing a "learning check" launching from satchel -- TO ENABLE, set v-if to true in the div above
		open_learning_check() {
			let s = `<div style="margin:-20px; background-color:#fff; padding-top:12px;"><iframe src="http://localhost:7048/itembank/1323" style="width:100%; height:640px; border:0; background-color:#fff"></iframe></div>`
			this.$alert({
				text: s,
				dialogMaxWidth:1200,
				acceptText: 'Done',
			})
		},
	}
}
</script>

<style lang="scss">
.k-case-tree-item-tile-outer {
	width:600px;
	max-width:calc(50vw - 28px);
	border-radius:8px;
	border:1px solid #ddd;
	padding:8px;
	// elevation-2
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
	background-color:#fff;

	font-size:18px;
	line-height:24px;
	position:relative;
	// margin:6px 0;

	pointer-events: auto;

	.fa-thumbtack {
		transform:rotate(30deg);
	}
}

.k-case-tree-item-not-yet-adopted-message, .k-case-tree-item-no-longer-adopted-message, .k-case-tree-item-scheduled-no-longer-adopted-message, .k-case-tree-item-public-review-description { 
		display:none; 
		text-align:center;
		font-size:0.8em;
		color:#fff;
		border-radius:4px;
}
.k-case-tree-item-tile-outer.k-case-tree-item-not-yet-adopted {
	background-color:#eee;
	.k-case-tree-item-not-yet-adopted-message {
		display:block;
		background-color:#444;
	}
}
.k-case-tree-item-tile-outer.k-case-tree-item-no-longer-adopted {
	background-color:$v-red-lighten-5;
	.k-case-tree-item-no-longer-adopted-message {
		display:block;
		background-color:$v-red-darken-4;
	}
}
.k-case-tree-item-tile-outer.k-case-tree-item-scheduled-no-longer-adopted {
	.k-case-tree-item-scheduled-no-longer-adopted-message {
		display:block;
		background-color:$v-red-lighten-4;
		color:#000;
	}
}

.k-case-tree-item-public-review-description {
	text-indent:0;
	margin-left:-24px;
	margin-right:4px;
	margin-top:8px;
	display:block;
	background-color:$v-pink-lighten-5;
	border:1px solid $v-pink-lighten-4;
	color:#000;
}

.k-case-tree-item-tile-framework-header {
	background-color:#333;
	border-radius:8px;
	color:#fff;
	text-align:center;
	padding:4px;
	font-size:16px;
	margin:8px 8px 4px 8px;
}

.k-case-tree-item-tile-inner {
	display:flex;
	align-items:flex-start;
	justify-content: flex-start;
	overflow:hidden;
	padding:8px 0;
}

.k-case-tree-item-tile-outer {
}

// adoptionStatus indicators
.k-case-tree-item-adoption-status {
	display:inline-block;
	margin-left:4px;
	padding:0px 6px;
	border:2px solid transparent;
	border-radius:4px;
	text-indent:0;
	font-weight:bold;
	color:#fff;
	background-color:#444;
}

.k-case-tree-item-adoption-status-private_adopted, .k-case-tree-item-adoption-status-adopted, .k-case-tree-item-adoption-status-private_implemented, .k-case-tree-item-adoption-status-implemented {
	background-color:$v-green-darken-3;
}

.k-case-tree-item-adoption-status-private_pending_implementation, .k-case-tree-item-adoption-status-pending_implementation {
	background-color:$v-lime-darken-3;
}

// we used to use "proposed" status for cases where the issuing agency isn't the publisher...
.k-case-tree-item-adoption-status-private_proposed, .k-case-tree-item-adoption-status-proposed {
	background-color:$v-blue-darken-3;
}

// now we check to see if the creator and publisher don't match, and apply this class if so
// note that by putting the class here, it overwrites the "adopted"/"implemented" classes, but not the other ones below
.k-case-tree-item-adoption-status-unofficial {
	background-color:$v-blue-darken-3;
}

.k-case-tree-item-adoption-status-private_draft, .k-case-tree-item-adoption-status-draft {
	background-color:$v-red-darken-3;
}

.k-case-tree-item-adoption-status-private_deprecated, .k-case-tree-item-adoption-status-deprecated, .k-case-tree-item-adoption-status-private_retired, .k-case-tree-item-adoption-status-retired {
	background-color:$v-orange-darken-3;
}
.k-case-tree-item-adoption-status-private_draft, .k-case-tree-item-adoption-status-private_adopted, .k-case-tree-item-adoption-status-private_proposed, .k-case-tree-item-adoption-status-private_deprecated, .k-case-tree-item-adoption-status-private_retired {
	border:2px dashed #666;
}
.k-case-tree-item-details-exemplar-body {
	overflow: auto;

	.k-big-table {
		table-layout: fixed;

		th {
			font-size:0.6em;
			vertical-align:bottom;
			white-space:nowrap;
		}
		td {
			font-size:0.6em;
			vertical-align:top;
		}
	}
}

.k-case-tree-item-tile-outer-has-supp-notes {
	width:800px;
}

// modifications for inline tiles, which could be because the tree is "minimized", or we're on a phone-sized screen
.k-case-tree-item-tile-outer-inline {
	width:calc(100% - 8px);	// this may need to be adjusted for minimized-but-not-phone-sized situation
	max-width: none;
	position:absolute;
	z-index:1;
	left:-10px;
	background-color:#eee;
	font-size:inherit;
	line-height: inherit;
	margin:0px 0 0px 15px;
	padding:4px;
	box-shadow:none!important;

	.k-case-tree-item-details-notes-header, .k-case-tree-item-details-exemplar-header {
		background-color:#eee!important;
	}

	.k-case-tree-item-tile-inner {
		padding:0;
	}

	.k-case-tree-item-statement-hcs:hover {
		text-decoration:underline;
		cursor:pointer;
	}

	.k-case-tree-item-statement:hover {
		cursor:pointer;
	}

	.k-case-tree-item-node-btn {
		margin-right:4px;
		margin-top:-1px;
		width:22px!important;
		height:22px!important;
		.fa-chevron-circle-right { font-size:18px!important; }
		.fa-dot-circle { font-size:18px!important; }
	}
}



.k-case-tree-item-tile {
	padding:1px 0;
	border-radius:6px;
	border:1px solid transparent;
	max-height:calc(100vh - 136px);
	overflow:auto;

	.k-case-tree-item-code-plus-statement {
		white-space: normal;
		width:calc(100% - 28px);	// if this is just "100%", we get some bad styling if the official source URL is really long
		cursor:inherit;
	}

	.k-case-tree-item-statement {
		text-indent:0px;
	}

	.k-tile-comments-wrapper {
		text-indent:0;
		margin-left:-28px;
		margin-top:8px;
	}

	.k-case-tree-item-details {
		text-indent: 0;
		margin-left:-20px;
		margin-right:8px;
		font-size:0.78em;
		line-height:1.35em;

		.k-case-tree-item-details-resource-link {
			margin:8px 0;
			text-align:right;
		}

		.k-case-tree-item-details-inner {
			margin-top:8px;
			border-top:1px solid #ccc;
			padding-top:8px;
		}

		.k-case-tree-item-details-inner-no-border {
			border-top:0;
		}

		.k-case-tree-item-details-inner-item {
			text-align:center;
			display:flex;
			justify-content: space-around;
			align-items:center;
		}

		.k-case-tree-item-details-inner-document {
			font-size:1.0em;
			line-height:1.4em;
		}

		.k-case-tree-item-details-label {
			font-weight:bold;
			color:#666;
		}

		.k-case-tree-item-details-notes, .k-case-tree-item-details-exemplar {
			text-indent: 0;
			border:2px solid #aaa;
			border-radius:5px;
			position:relative;
			margin-top:16px;
			padding:8px;

			.fr-view {
				font-size:1.15em;
				line-height:1.4em;
			}

			.k-case-tree-item-details-notes-header, .k-case-tree-item-details-exemplar-header {
				position:absolute;
				margin-top:-17px;
				margin-top:-1.6em;
				margin-left:0px;
				background-color:#fff;
				color:#666;
				font-weight:bold;
				font-size:0.85em;
				padding:0 4px;

				a {
					color:#999;
					font-size:0.8em;
				}
			}
		}

		.k-exemplar-block-header {
			margin:8px 0 4px 0;
			border-top:1px solid #999;
			padding-top:8px;
			cursor:pointer;

			// paragraphs inside k-exemplar-block-header should be treated as inline-block
			p {
				margin:0;
				display:inline-block;
			}

			.fas {
				font-size:18px;
				line-height:16px;
				width:15px;
				color:$v-grey-darken-2;
				text-align:center;
			}
		}
		.k-exemplar-block-header:first-of-type {
			border-top:0;
			padding-top:0;
			margin-top:4px;
		}

		.k-exemplar-block-body {
		}

		.k-exemplar-block-body-no-header {
			margin:8px 0;
			border-top:1px solid #999;
			padding-top:8px;
		}

		.k-exemplar-block-body-no-header:first-of-type {
			border-top:0;
			padding-top:0;
			margin-top:4px;
		}
	}

	.k-case-tree-item-node-btn {
		width:24px!important;
		height:24px!important;
		.fa-chevron-circle-right { font-size:20px!important; }
		.fa-dot-circle { font-size:20px!important; }
		.fa-map { font-size:17px!important; }
	}
	.k-case-tree-item-track-changes-btn .fas {
		font-size:20px!important;
	}
}

.k-case-tree-item-tile-selected .k-case-tree-item-details {
	margin-left:-31px;	// this compensates for the icon that indicates the item is selected
	// margin-right:19px;
}

.k-case-tree-markdown {
	// markdown styles
	p, ul { margin-bottom:8px; }
}

.k-case-tree-item-tile-node {
	// max-width:600px;
}

.k-case-tree-item-tile-leaf {
}

.k-case-tree-item-tile-search-match {
	// background-color:rgba(0, 0, 0, 0.1)!important;
}

.k-case-tree-item-tile-last-clicked > .k-case-tree-item {
	// border-color:$v-grey-darken-2!important;
}

.k-case-tree-item-tile-close-btn {
	position:absolute;
	right:-8px;
	top:-8px;
	background-color:#fff;
}

// .k-case-tree-item-tile-active-with-pinned-items {
// 	width:500px;
// 	max-width:500px;
// }

.k-case-tree-pinned-items-wrapper {
	.k-case-tree-item-tile-outer:not(.k-case-tree-item-tile-outer-full-screen) {
		width:320px;
		max-width:320px;
		padding:0px 12px 0px 4px;
		border-color: transparent!important;
		background-color:#f8f8f8;
		margin-top:8px;
		margin-bottom:8px;

		font-size:12px;
		line-height:15px;
		margin-left:0;

		.k-case-tree-item-node-btn {
			margin-right:3px;
			margin-top:-2px;
			width:20px!important;
			height:20px!important;
			.fa-chevron-circle-right { font-size:15px!important; }
			.fa-dot-circle { font-size:15px!important; }
		}
		// don't show track changes indicators in pinned tiles
		.k-case-tree-item-track-changes-btn {
			display:none;
		}

		.k-case-tree-item-statement-hcs:hover {
			text-decoration:underline;
			cursor:pointer;
		}

		.k-case-tree-item-statement:hover {
			cursor:pointer;
		}

		.k-case-tree-item-associations {
			display:none;
		}

		.k-case-tree-item-details {
			// for now, at least, we don't show any details in pinned standards, because you can click to expand and show everything
			display:none;
		}

		.k-case-tree-item-details-inner {
			display:none!important;
		}

		.k-case-tree-item-tile-close-btn {
			border:1px solid #ccc;
			width:22px;
			height:22px;
			right:-5px;
			top:-5px;
			.fa-thumbtack { font-size:13px!important; }
		}
	}
}

// @media (max-height: 824px) {
// 	.k-case-tree-item-tile-outer-full-screen {
// 		margin-top:0;
// 	}
// }

.editRubricCard {
	background: white
}

</style>
